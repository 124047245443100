import React, { useEffect, useRef, useState } from "react"

interface ImageProps {
  src: string
  alt?: string
  className?: string
  style?: React.CSSProperties
}

const defaultImage = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIj48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgZmlsbD0iI2UzZTNlMyIvPjwvc3ZnPg=="
const brokenImage = "https://cdn.parcelpanel.com/www/broken-image.svg"

const LazyLoadImage = ({ src, ...props }: ImageProps) => {
  const [imageSrc, setImageSrc] = useState(defaultImage)
  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    let observer: IntersectionObserver

    if (imgRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => {
          // 当图片进入可视区域时，设置图片地址进行加载
          if (entry.isIntersecting && imgRef.current) {
            setImageSrc(src)
            observer.unobserve(imgRef.current)
          }
        },
        {
          rootMargin: "0px 0px 200px 0px", // 可视区域的边距设置为200px
        },
      )

      // 监听图片加载失败
      imgRef.current.onerror = () => {
        console.warn(`Image loaded failed, SRC=${imgRef.current?.src}`)
        setImageSrc(brokenImage)
      }

      observer.observe(imgRef.current)
    }

    return () => {
      if (observer && observer.unobserve && imgRef.current) {
        observer.unobserve(imgRef.current)
      }
    }
  }, [src])

  return <img ref={imgRef} alt={props.alt} {...{ src: imageSrc, ...props }} />
}

export default LazyLoadImage
