import { Badge, Card } from "@shopify/polaris"
import { InfoIcon } from "@shopify/polaris-icons"
import qs from "qs"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { ShipmentsAndLookupData } from "@/api/dashboard/type"
import { ToProtection } from "@/component/Protection"
import OrdersNav from "@/enums/orders-nav"
import { ShipmentStatus } from "@/models/IShipmentsLookupsData"
import stores from "@/stores"

import OrderStatusCard from "./OrderStatusCard"

interface OrderStatusListProps {
  loading?: boolean
  dataset: ShipmentStatus[]
  orderTime: {
    selected?: string
    orderedAtMin?: string
    orderedAtMax?: string
  }
  chartData: ShipmentsAndLookupData["shipments_lookups"]["shipment_status_chart_data"]
}

export default function OrderStatusList({
  loading,
  dataset,
  orderTime,
  chartData,
}: OrderStatusListProps){
  const { t } = useTranslation("dashboard", { keyPrefix: "OrderStatusBar" })
  const navigate = useNavigate()
  const status = new Map<number, number>()
  const { selected, orderedAtMin, orderedAtMax } = orderTime
  const { exception, failed_attempt, pending } = chartData

  dataset.forEach(item => status.set(item.type, item.value))

  const handleClick = (status:number) => {
    if (!loading) {
      navigate({
        pathname: "/orders",
        search: qs.stringify({
          orderedAt: selected && selected !== "0" ? "1" === selected ? "Today" : `Last ${stores.EventsStore.selected} days` : void 0,
          orderedAtMin,
          orderedAtMax,
          status: OrdersNav[status],
        }, {
          skipNulls: true,
        }),
      })
    }
  }

  const OrderStatusList = [
    {
      title: t("exception"),
      description: t("exceptionDesc"),
      type: 7,
      value: status.get(7),
      onClick: handleClick,
      chartData: exception,
      extra: (
        <ToProtection pointType="exception_card"  needCheckShipment />
      ),
    },
    { title: t("failedAttempt"),
      description: t("failedAttemptDesc"),
      type: 6,
      value: status.get(6),
      onClick: handleClick,
      chartData: failed_attempt,
    },
    { title: t("pending"),
      description: t("pendingDesc"),
      type: 1,
      value: status.get(1),
      onClick: handleClick,
      chartData: pending,
    },
  ]

  return <Card padding={"300"}>
    <div className={"flex flex-wrap gap-3 flex-col md:flex-row"}>
      {
        OrderStatusList.map((status, index) => <Fragment key={status.type}>
          <OrderStatusCard {...status} />
          {index < OrderStatusList.length - 1
            && <div className={"w-full h-[1px] md:w-[1px] md:h-[66px] bg-[var(--p-color-border-secondary)]"} />
          }
        </Fragment>)
      }
    </div>
  </Card>
}
