import { useTranslation } from "react-i18next"

import { DateOption } from "@/pages/Orders/components"

export enum AppliedStatus {
  "CoverStatus"="coverStatus",
  "CreatedDate"="createdDate"
}

export enum auditRetEnum {
  /**
   * 商店尚未上线、版本不支持，因此无法启用运输保险服务
   */
  "planError"="Plan error",
  /**
   * 货币不支持
   */
  "currencyTypeError"="Currency not supported",
  /**
   * 非付费用户
   */
  "nonPayingUsers"="Non paying users",
  /**
   * 黑户
   */
  "blackList"="Black list",
  "examPassed"="Exam passed",
  /**
   * 还在审核
   */
  "underReview"="Under review",
  "sorryGreenDelivery"="Sorry, Green Delivery",
  "talkToAnExpert"="Talk to an expert",
  /**
   * 欠款未缴
   */
  "paymentIssue"="Payment issue",
  /**
   * 赔付太差
   */
  "highLossRatio"="High loss ratio",
  "success"="success"
}

export const getDataTime = () => {
  const { t } = useTranslation("orders")

  return [
    { label: t("LastNDays", { day: 7 }), value: DateOption.PastWeek },
    { label: t("LastNDays", { day: 30 }), value: DateOption.PastMonth },
    { label: t("LastNDays", { day: 90 }), value: DateOption.PastQuarter },
  ]
}

export const getCoverStatus = () => {
  const { t } = useTranslation("delivery_guarantee")

  return [
    { label: t("CoverStatus.Pending"), value: "Pending" },
    { label: t("CoverStatus.Active"), value: "Active" },
    { label: t("CoverStatus.Cancelled"), value: "Cancelled" },
    { label: t("CoverStatus.Expired"), value: "Expired" },
  ]
}

export const getClaimsStatus = () => {
  const { t } = useTranslation("delivery_guarantee")

  return [
    { label: t("CoverStatus.Accepted"), value: "Accepted" },
    { label: t("CoverStatus.Pending"), value: "Pending" },
    { label: t("Closed"), value: "Closed" },
    { label: t("CoverStatus.Rejected"), value: "Rejected" },
  ]
}


export const getDays = {
  "Last 7 days": "LAST_7_DAYS",
  "Last 30 days": "LAST_30_DAYS",
  "Last 90 days": "LAST_90_DAYS",
}

