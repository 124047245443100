import {
  ActionList, Button,
  Card, Checkbox,
  Icon,
  InlineGrid, InlineStack,
  Key,
  Layout,
  Page, Popover,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris"
import { EmailIcon, OrdersStatusIcon, ViewIcon } from "@shopify/polaris-icons"
import { find, isEmpty } from "lodash-es"
import { Observer } from "mobx-react"
import qs from "qs"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

import { APICode } from "@/api/APICode"
import TP2 from "@/api/TP2"
import { ITrackingResultItem } from "@/api/TP2/tracking-info"
import { Container, hasPermission, TrackInfo } from "@/component"
import TrackStatusProgressBar from "@/component/TrackStatusProgressBar/TrackStatusProgressBar"
import { NotificationHistoryModalMobx, TagsManageModalMobx } from "@/pages/Orders/components"
import { ShipmentInfo } from "@/pages/Orders/components/OrderInfo"
import { UpdateStatusSelectFrom } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"
import { TrackStatus } from "@/utils/TrackStatus"
import UrlUtils from "@/utils/UrlUtils"
import IStatus = TrackStatus.IStatus
import { History } from "@shopify/app-bridge/actions"
import type { ActionListItemDescriptor } from "@shopify/polaris/build/ts/src/types"

import { postRouteMessage } from "@/admin-bridge/admin-bridge"
import PermissionBadge from "@/component/PermissionBadge"
import Permission from "@/enums/permission"
import { BetaFeature } from "@/models/IUserInfo"
import { languageList, languageType } from "@/pages/Settings/EmailEdit/type"
import { getLanguagesList } from "@/pages/Settings/EmailEdit/utils"
import AppBridge from "@/utils/appBridge"
import { canIUseBetaFeature } from "@/utils/BetaFeature"

import styles from "./Orders.module.scss"

interface OrdersDetailProps {
  numberID?: string
  numberIDList?: string
}

const OrdersDetail = ({ numberID, numberIDList }: OrdersDetailProps) => {
  const { t } = useTranslation("orders")
  const [searchParams] = useSearchParams()
  const navigator = useNavigate()
  const ordersQuery = searchParams.get("query")
  let ordersListString = searchParams.get("orders")
  const [changeLoading, setChangeLoading] = useState(false)

  if (isEmpty(ordersListString)) {
    if (numberIDList) {
      ordersListString = numberIDList
    } else if (numberID) {
      ordersListString = numberID
    }
  }

  const ordersList = ordersListString ? ordersListString.split(",") : []

  let __index = ordersList.findIndex((item) => item === numberID)

  if (searchParams.has("orderIndex")) {
    __index = Number(searchParams.get("orderIndex") || "0")
  } else {
    if (__index === -1) {
      __index = 0
    }
  }

  // order index
  const [orderIndex, setOrderIndex] = useState(__index)

  const { userInfoSore, ordersStore } = stores


  const [data, setData] = useState<{
    expandTrackInfo: boolean
    track_link: string
    tracking?: ITrackingResultItem
  }>({ expandTrackInfo: false, track_link: "", tracking: undefined })


  const onBack = () => {
    navigator("/orders" + decodeURIComponent(typeof ordersQuery === "string" ? ordersQuery : ""))
  }

  const getData = (id: number, executiveTranslation: string, locale: languageType | string) => {
    if (ordersStore.orderInfoDataLoadedTime === 0){
      ordersStore.setOrderInfoData(undefined)
    }

    // 如果参数都没有，直接跳回Orders
    if (!ordersListString || !id) {
      navigator("/orders")
    }

    if (executiveTranslation === "false") {
      locale = userInfoSore.data.primary_locale || "en"
    }

    // console.log('OrderInfo.tsx', '如果和上一次不相同，就去执行获取数据', [this.props.id, prevProps.id])
    id && TP2.getTrackingInfo(id, locale, executiveTranslation)
      .then(({ data: { data, code, msg } }) => {
        if (APICode.OK !== code) {
          stores.ordersStore.setOrderInfoShow(false)
          return
        }

        stores.ordersStore.setOrderInfoData(data)
        stores.ordersStore.setOrderInfoDataLoadedTime(new Date().getTime())

        const tracking = find<ITrackingResultItem>(data?.tracking, item => parseInt(item.nid + "") === parseInt(id + ""))

        setData({ expandTrackInfo: false, track_link: data.track_link, tracking })

      })
      .catch(() => {
        // MARK: chuwen 2022/7/12 10:56 当出现错误就关闭弹窗
        stores.ordersStore.setOrderInfoShow(false)
      })
      .finally(() => {
        setChangeLoading(false)
      })
  }

  const [executiveTranslation, setExecutiveTranslation] = useState("")

  const [selectTargetLanguage, setSelectTargetLanguage] = useState(userInfoSore.data.primary_locale || "en")
  const handleLanguageChange = useCallback((value: string) => {
    setExecutiveTranslation("true")
    setSelectTargetLanguage(value as languageType)
  }, [])

  let actuallyTranslationExecuted = data.tracking?.translation_executed === true

  if (executiveTranslation !== "") {
    actuallyTranslationExecuted = executiveTranslation === "true"
  }

  const toggleExecutiveTranslation = useCallback(function (value: boolean) {
    if (!value) {
      setExecutiveTranslation("false")
    } else {
      setExecutiveTranslation("true")
    }
  }, [executiveTranslation])

  const [translateLanguageListActive, setTranslateLanguageListActive] = useState(false)
  const toggleTranslateLanguageList = useCallback(() => {
    setTranslateLanguageListActive(prevState => !prevState)
  }, [])

  let currentSelectLanguage = languageList.find(item => item.value === selectTargetLanguage)?.name || "English"

  if (selectTargetLanguage === userInfoSore.data.primary_locale) {
    currentSelectLanguage += " (default)"
  }

  // 翻译物流信息
  const hasTranslatePermission = hasPermission(Permission.AutoTranslateTrackingDetails)
  const translateLanguageList: ActionListItemDescriptor[] = useMemo(() => {
    return getLanguagesList(userInfoSore.data.shop_locales, (userInfoSore.data.primary_locale || "en") as string)
      .map(item => ({
        content: item.isDefault ? `${item.name} (default)` : item.name,
        active: item.value === selectTargetLanguage,
        onAction: () => {
          if (hasTranslatePermission) {
            handleLanguageChange(item.value)
          }
        },
      }))
  }, [userInfoSore.data.shop_locales, currentSelectLanguage])

  useEffect(() => {
    if (canIUseBetaFeature(BetaFeature.NEW_SHIPMENT_INFO_PAGE)){
      const targetPath = `/orders/shipments/${ordersList[orderIndex]}`

      if (AppBridge.exist()) {
        // 参考文档：https://shopify.dev/tools/app-bridge/actions/navigation/history
        const history = History.create(AppBridge.app)

        history.dispatch(History.Action.PUSH, targetPath)
      } else {
        postRouteMessage(targetPath)
      }
    }

    getData(Number(ordersList[orderIndex]), executiveTranslation, selectTargetLanguage)
  }, [orderIndex, executiveTranslation, selectTargetLanguage])

  // index切换时全局loading
  useEffect(() => {
    ordersStore.setOrderInfoDataLoadedTime(0)
  }, [orderIndex])

  // 局部loading
  useEffect(() => {
    setChangeLoading(true)
  }, [executiveTranslation, selectTargetLanguage])

  const getTransitDays = () => {
    const transitDay = data.tracking?.transitDay

    return transitDay ? t("OrderInfo.nDay", { count: transitDay }) : "-"
  }

  // 计算标题文本
  const trackInfoTitle = () => {
    const status = data.tracking?.status || "-"

    return `${status} · ${getTransitDays()}`
  }

  // 上一页
  const handlePgUp = () => {
    setOrderIndex(orderIndex - 1 < 0 ? 0 : orderIndex - 1)
    UrlUtils.setSearchByKey("orderIndex", String(orderIndex))
  }
  // 最大index
  const maxIndex = ordersList.length - 1
  // 下一页
  const handlePgDown = () => {
    setOrderIndex(orderIndex + 1 > maxIndex ? maxIndex : orderIndex + 1)
    UrlUtils.setSearchByKey("orderIndex", String(orderIndex))
  }

  const navigate = useNavigate()


  return (
    <Observer>{() => {
      const { orderInfoData, orderInfoDataLoadedTime } = ordersStore
      const dataLoading = orderInfoDataLoadedTime === 0
      const numberID = Number(ordersList[orderIndex])
      const disabledTranslate = dataLoading || !hasTranslatePermission

      return (
        <Container id="PageOrdersDetail" className={"pb-5"}>
          <Page
            backAction={{ content: "Back", onAction: onBack }}
            title={orderInfoData?.order}
            titleMetadata={dataLoading ? <div className={"w-20"}><SkeletonDisplayText size="small" /></div> : null}
            secondaryActions={[
              {
                content: t("OrderInfo.ViewOnTrackingPage"),
                loading: dataLoading,
                icon: ViewIcon,
                onAction: () => window.open(orderInfoData?.track_link, "_blank"),
              },
            ]}
            actionGroups={[
              {
                // @ts-ignore
                title: t("email_edit:MoreActions"),
                disabled: dataLoading,
                actions: [
                  {
                    // @ts-ignore
                    content: t("orders:ViewNotificationHistory"),
                    prefix: <Icon source={EmailIcon} />,
                    accessibilityLabel: "Individual action label",
                    onAction: () => stores.ordersStore.loadMailSendRecords(numberID),
                  },
                  {
                    // @ts-ignore
                    content: t("orders:ManuallyUpdateStatus"),
                    prefix: <Icon source={OrdersStatusIcon} />,
                    accessibilityLabel: "Individual action label",
                    onAction: () => {
                      const { search } = window.location

                      // 构建正则表达式，查找orderIndex的值
                      const regex = new RegExp("(orderIndex=)([^&]*)")

                      const searchUrl = search.replace(regex, "$1" + orderIndex)

                      const searchObj = {
                        from: UpdateStatusSelectFrom.MultiSelect,
                        query: encodeURIComponent(searchUrl),
                        number_ids: [numberID], // 当没有选择更多时，会有此参数
                      }

                      navigate({
                        pathname: "/orders/update-status",
                        search: qs.stringify(searchObj),
                      })
                    },
                  },
                ],
              },
            ]}
            pagination={{
              hasPrevious: dataLoading ? false : orderIndex > 0,
              hasNext: dataLoading ? false : orderIndex < maxIndex,

              onNext: handlePgDown,
              onPrevious: handlePgUp,

              previousKeys: [Key.KeyJ],

              // @ts-ignore
              previousTooltip: t("common:Pagination.previous") + " (J)",

              nextKeys: [Key.KeyK],

              // @ts-ignore
              nextTooltip: t("common:Pagination.next") + " (K)",
            }}
          >
            <div className={styles.sectionContainer}>
              <Layout>
                <Layout.Section>
                  <Card>
                    <InlineGrid columns={{ xs: "auto", sm: "auto", md: "1fr auto", lg: "1fr auto", xl: "1fr auto" }}>
                      <Text as="h2" variant="headingSm">
                        {t("TrackingDetails")}
                      </Text>

                      <div className={"flex gap-2 items-center flex-wrap"}>
                        <Checkbox
                          label={t("Translate")}
                          disabled={disabledTranslate || changeLoading}
                          checked={actuallyTranslationExecuted}
                          onChange={toggleExecutiveTranslation}
                        />
                        <div className={"flex items-center flex-wrap"}>
                          <Popover
                            active={translateLanguageListActive}
                            activator={<Button disabled={dataLoading || changeLoading}
                              disclosure
                              onClick={toggleTranslateLanguageList}
                            >{currentSelectLanguage}</Button>}
                            autofocusTarget={"first-node"}
                            onClose={toggleTranslateLanguageList}
                          >
                            <ActionList
                              actionRole={"menuitem"}
                              items={translateLanguageList}
                              onActionAnyItem={toggleTranslateLanguageList}
                            />
                          </Popover>

                          {!hasPermission(Permission.AutoTranslateTrackingDetails) && <PermissionBadge
                            text={"Professional"}
                            permission={Permission.AutoTranslateTrackingDetails}
                          />}
                        </div>
                      </div>
                    </InlineGrid>

                    <div className={"flex flex-col gap-[var(--p-space-300)] mt-4"}>
                      {
                        dataLoading || changeLoading
                          ? <>
                            <SkeletonBodyText lines={1} />

                            <SkeletonBodyText lines={14} />
                          </>
                          : <>
                            <Text variant="headingMd" as="h2">{trackInfoTitle()}</Text>

                            {/* 订单状态进度指示器 */}
                            <TrackStatusProgressBar orderStatus={(data.tracking?.stausDataNum ?? 1) as IStatus} />

                            {/* 物流信息 */}
                            {
                              !data.tracking?.trackinfo.length
                                ? (<p>{t("OrderInfo.NotYet")}</p>)
                                : <TrackInfo tracking={data.tracking} />
                            }
                          </>
                      }
                    </div>
                  </Card>

                </Layout.Section>
                <Layout.Section variant="oneThird">
                  <ShipmentInfo changeLoading={changeLoading} tracking={data.tracking} />
                </Layout.Section>
              </Layout>

            </div>
            <TagsManageModalMobx type={"detail"} />

            <NotificationHistoryModalMobx />
          </Page>
        </Container>
      )
    }}</Observer>
  )
}

export default OrdersDetail
