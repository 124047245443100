// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { convertToRaw } from "draft-js"
// @ts-ignore
import draftToHtml from "draftjs-to-html"
import { flatten } from "flat"
import { cloneDeep, isEqual } from "lodash-es"
import { deepObserve, IDisposer } from "mobx-utils"

import EDDApi from "@/api/settings/preEDD"
import { stringToEditorState } from "@/pages/Settings/PrePurchaseEDD/utils/index"
import stores from "@/stores"
import { compareObjects, deepMerge } from "@/utils/ObjectUtils"
import toast from "@/utils/toast"

export let disposer: IDisposer | null = null
export let defaultOptions: any

/**
 * 设置初始值
 * @param options
 */
const setInitOptions = (options: any) => {
  defaultOptions = cloneDeep(options)
}


/**
 * 获取初始值配置
 */
const getInitOptions = () => {
  return defaultOptions
}

/**
 * 注册监听器
 */
const registerListener = () => {
  const { PrePurchaseEDDStore } = stores

  disposer = deepObserve(PrePurchaseEDDStore.widgetData, (change) => {
    if (change.type === "update" && change.observableKind === "object") {
      const base = flatten(defaultOptions)
      const now = flatten(PrePurchaseEDDStore.widgetData.localized_settings)

      const isChange = !isEqual(base, now)

      if (PrePurchaseEDDStore.widgetSaveBar.show !== isChange) {
        PrePurchaseEDDStore.setWidgetSaveBar("show", isChange)
      }
    }
  })
  return true
}

const destroyListener = () => {
  const { PrePurchaseEDDStore } = stores

  disposer && disposer()
  PrePurchaseEDDStore.setWidgetSaveBar("show", false)
  PrePurchaseEDDStore.setWidgetSaveBar("loading", false)
}

/**
 * 保存数据
 */
const saveConfig = ({ successText, errorText, lengthError }: { successText: string, errorText: string, lengthError: string }) => {
  const { PrePurchaseEDDStore } = stores

  PrePurchaseEDDStore.setWidgetSaveBar("loading", true)

  const changes = compareObjects(PrePurchaseEDDStore.widgetData.localized_settings, getInitOptions())

  return new Promise((resolve, reject) => {
    for (const lang  in changes){
      if (changes[lang].translations){
        const { content } = changes[lang].translations

        if (content && stringToEditorState(content)){
          const state = stringToEditorState(content)?.getCurrentContent()

          if (state){
            changes[lang].translations.content = draftToHtml(convertToRaw(state))
            PrePurchaseEDDStore.setEditorStateMap(lang, stringToEditorState(content))
          }
        }

        if (content.length > 4096){
          PrePurchaseEDDStore.setWidgetSaveBar("loading", false)

          toast(lengthError, 2000, true)
          reject(lengthError)
          return
        }
      }
    }

    EDDApi.setWidgetConfig({
      widget: { localized_settings: changes },
    }).then(() => {
      destroyListener() // 销毁监听
      const newInitData = deepMerge(PrePurchaseEDDStore.widgetData.localized_settings, changes)

      setInitOptions(newInitData)
      PrePurchaseEDDStore.updateLocalizedSettings()
      registerListener() // 启动监听
      toast(successText, 2e3)
      resolve(true)
    })
      .catch((e) => {
        toast(errorText, 2e3, true)
        reject(e)
      })
      .finally(() => {
        PrePurchaseEDDStore.setWidgetSaveBar("loading", false)
      })
  })
}

/**
 * 放弃更改数据
 */
const discardConfig = () => {
  const { PrePurchaseEDDStore } = stores
  const defaultConfig = getInitOptions()

  destroyListener()// 先销毁监听器
  PrePurchaseEDDStore.setData("widgetData.localized_settings", defaultConfig)
  PrePurchaseEDDStore.cancelWidgetEdit()
  registerListener()// 后注册监听器
}

export default {
  destroyListener,
  discardConfig,
  getInitOptions,
  registerListener,
  saveConfig,
  setInitOptions,
}
