// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { flatten } from "flat"
import { cloneDeep, isEqual } from "lodash-es"
import { deepObserve, IDisposer } from "mobx-utils"

import EDDApi from "@/api/settings/preEDD"
import stores from "@/stores"
import toast from "@/utils/toast"

export let disposer: IDisposer | null = null
export let defaultOptions: any
export let modulesDisposer: IDisposer | null = null

/**
 * 设置初始值
 * @param options
 */
const setInitOptions = (options: any) => {
  defaultOptions = cloneDeep(options)
}


/**
 * 获取初始值配置
 */
const getInitOptions = () => {
  return defaultOptions
}

/**
 * 注册监听器
 */
const registerListener = () => {
  const { PrePurchaseEDDStore } = stores

  disposer = deepObserve(PrePurchaseEDDStore, (change) => {
    if (change.type === "update" && change.observableKind === "object") {
      const base = flatten(defaultOptions)
      const now = flatten(PrePurchaseEDDStore.rulesData)

      const isChange = !isEqual(base, now)

      if (PrePurchaseEDDStore.saveBar.show !== isChange) {
        PrePurchaseEDDStore.setSaveBar("show", isChange)
      }
    }
  })
  return true
}

const destroyListener = () => {
  const { PrePurchaseEDDStore } = stores

  disposer && disposer()
  PrePurchaseEDDStore.setSaveBar("show", false)
  PrePurchaseEDDStore.setSaveBar("loading", false)
}

/**
 * 保存数据
 */
const saveConfig = ({ successText, errorText }: { successText: string, errorText: string }) => {
  const { PrePurchaseEDDStore } = stores

  PrePurchaseEDDStore.setSaveBar("loading", true)

  const rule  = PrePurchaseEDDStore.ruleUpdateInput

  EDDApi.setRule({ rule: rule }).then(() => {
    destroyListener() // 销毁监听
    setInitOptions(rule)
    registerListener() // 启动监听
    toast(successText, 2e3)
  })
    .catch(() => {
      toast(errorText, 2e3, true)
    })
    .finally(() => {
      PrePurchaseEDDStore.setSaveBar("loading", false)
    })
}

/**
 * 放弃更改数据
 */
const discardConfig = () => {
  const { PrePurchaseEDDStore } = stores
  const defaultConfig = getInitOptions()

  destroyListener()// 先销毁监听器
  PrePurchaseEDDStore.setData("rulesData", defaultConfig)
  registerListener()// 后注册监听器
}


/**
 * 注册二级监听器
 */
const registerModuleListener = () => {
  const { eddModulesStore, PrePurchaseEDDStore } = stores

  modulesDisposer = deepObserve(eddModulesStore, (change) => {
    if (change.type === "update" && change.observableKind === "object" && change.name !== "saveBtnActive") {
      const base = flatten({
        fulfillment_workflow: PrePurchaseEDDStore.rulesData.default_rule?.fulfillment_workflow,
        transit_time: PrePurchaseEDDStore.rulesData.default_rule?.transit_time,
      })

      const now = flatten(eddModulesStore.config)
      const isChange = !isEqual(base, now)

      if (eddModulesStore.saveBtnActive !== isChange) {
        eddModulesStore.setSaveBtnActive(isChange)
      }
    }
  })
  return true
}

/**
 * 初始化ModulesStore
 */
const initModulesStore = () => {
  const { eddModulesStore, PrePurchaseEDDStore } = stores

  if (!PrePurchaseEDDStore.rulesData.default_rule) {
    return
  }
  eddModulesStore.setConfig(cloneDeep({
    fulfillment_workflow: PrePurchaseEDDStore.rulesData.default_rule.fulfillment_workflow,
    transit_time: PrePurchaseEDDStore.rulesData.default_rule.transit_time,
  }))

  eddModulesStore.setSaveBtnActive(false)
}

/**
 * 销毁二级监听器
 * @param options
 */
const destroyModuleListener = () => {
  modulesDisposer && modulesDisposer()
  modulesDisposer = null
  // 初始数据
  initModulesStore()
}

/**
 * 保存二级模块的数据
 */
const saveModulesConfig = () => {
  const { eddModulesStore, PrePurchaseEDDStore } = stores
  const config = cloneDeep(eddModulesStore.config)

  // 保存的时候过了国家为空的规则
  config.transit_time.rules = config.transit_time.rules.filter(item => item.countries.length > 0)

  PrePurchaseEDDStore.setDefaultRule("transit_time", config.transit_time)
  PrePurchaseEDDStore.setDefaultRule("fulfillment_workflow", config.fulfillment_workflow)
  eddModulesStore.setSaveBtnActive(false)
}

export {
  destroyListener,
  destroyModuleListener,
  discardConfig,
  getInitOptions,
  initModulesStore,
  registerListener,
  registerModuleListener,
  saveConfig,
  saveModulesConfig,
  setInitOptions,
}
