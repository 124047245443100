import { Button } from "@shopify/polaris"
import { XIcon } from "@shopify/polaris-icons"
import { CSSProperties, MouseEventHandler } from "react"
import { Link, LinkProps } from "react-router-dom"

import styles from "./ImageBanner.module.scss"

export interface Props {
  show: boolean;// 是否显示
  src: string;// 图片地址
  alt?: string;
  style?: CSSProperties
  to?: LinkProps["to"];
  target?: LinkProps["target"];

  onClose?(): void;// 关闭回调，如果有这个回调，那么就可以关闭
  onLinkClick?: MouseEventHandler<HTMLAnchorElement>
}

export const MarketingPicture = ({ show, to, onClose, src, alt, style ,onLinkClick}: Props) => {
  const imgMarkUp = <img className={styles.Img} src={src} alt={alt} />

  const handleCloseBanner = () => {
    typeof onClose === "function" && onClose()
  }

  return show ? <div className={styles.ImageBanner} style={style}>
    {onClose && <div className={styles.CloseButton}>
      <Button onClick={handleCloseBanner} icon={XIcon} variant="plain" />
    </div>}

    {to ? <Link to={to} onClick={onLinkClick}>{imgMarkUp}</Link> : imgMarkUp}
  </div> : null
}
