import {Select} from '@shopify/polaris';
import {useState, useCallback} from 'react';

interface PerPageSelectProps {
  onPerPageChange?: (perPage: number) => void;
}

function PerPageSelect({ onPerPageChange }: PerPageSelectProps) {
  const [selected, setSelected] = useState('20');

  const handleSelectChange = useCallback(
    (value: string) => {
      setSelected(value);
      if (onPerPageChange) {
        onPerPageChange(parseInt(value));
      }
    }, [onPerPageChange]);

  const options = [
    {label: '20', value: '20'},
    {label: '50', value: '50'},
    {label: '100', value: '100'},
    {label: '200', value: '200'},
    {label: '500', value: '500'},
  ];

  return (
    <Select
      label="Per page"
      labelInline
      options={options}
      onChange={handleSelectChange}
      value={selected}
    />
  );
}

export default PerPageSelect
