import { Button, Card, Text } from "@shopify/polaris"
import { XIcon } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"

import { openLink } from "@/utils/redirectShopify"

interface CrossSellBannerProps {
  isChina?: boolean
  onClose?: () => void
}
const CrossSellBanner = ({ isChina, onClose }:CrossSellBannerProps) => {
  const { t } = useTranslation("dashboard")
  const info = isChina
    ? { social: "WeCom", img_url: "https://cdn.parcelpanel.com/assets/admin/images/wecom.png", banner_url: "https://work.weixin.qq.com/ca/cawcde72cf688c044d?customer_channel=T-onboarding" }
    : { social: "Slack", img_url: "https://cdn.parcelpanel.com/assets/admin/images/slack.png", banner_url: "https://join.slack.com/t/parcelpanel-community/shared_invite/zt-2zol5x0o6-eIat3ZbDPG1_ENuVd5LfDA?utm_source=T-onboarding" }

  return <Card padding={"400"}>
    <div className={"flex justify-between"}>
      <div className={"flex max-w-max justify-between flex-wrap"}>
        <div className={"flex gap-3 flex-col max-w-[774px] sm:w-[70%] lg:w-[774px]"}>
          <Text as={"p"} variant={"headingSm"}>{t("CrossSellBanner.title", { social: info.social })}</Text>
          <Text as={"p"}>{t("CrossSellBanner.description", { social: info.social })}</Text>
          <div><Button variant={"secondary"} onClick={() => openLink(info.banner_url)}>{t("CrossSellBanner.action")}</Button></div>
        </div>
        <div className={"w-[100px]"}>
          <img className={"w-full"} src={info.img_url} alt={info.social} />
        </div>
      </div>
      <div>
        <Button onClick={onClose} variant={"tertiary"} icon={XIcon} />
      </div>
    </div>
  </Card>
}

export default CrossSellBanner
