import { Divider, LegacyCard, Text } from "@shopify/polaris"
import { t } from "i18next"
import { cloneDeep } from "lodash-es"
import { Observer, observer } from "mobx-react"
import numeral from "numeral"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { SpinnerContainer } from "@/component"
import CasingPly from "@/component/CasingPly/CasingPly"
import DynamicLoadError from "@/component/DynamicLoadError/DynamicLoadError"
import AnalyticList from "@/pages/Analytics/components/AnalyticList"
import { ShipmentsStatus } from "@/pages/Analytics/shipmentStatus"
import stores from "@/stores"
import { percentNumber } from "@/utils/analyticsUtils"
import { LanguageUtils } from "@/utils/languages"
import { IListenerNameEnum } from "@/utils/languages.d"

import styles from "./index.module.scss"

type IProps = WithTranslation<"dashboard">

// const ReactECharts = React.lazy(() => import("echarts-for-react"))
const DashboardLineChart = React.lazy(() => import("@/component/Charts/DashboardLineChart"))

// import DashboardLineChart from "@/component/Charts/DashboardLineChart"

@observer
class ShipmentsLookupsChart extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
    const { i18n } = this.props

    i18n.setDefaultNamespace("dashboard")
  }

  public componentDidMount() {
    // 因为切换语言，数据未改变，不会触发组件更新导致内容未翻译，所以用此方法触发
    LanguageUtils.addListener(IListenerNameEnum.ShipmentStatusListener, function () {
      const { shipmentStatus } = stores.shipmentsLookupsDataStore

      stores.shipmentsLookupsDataStore.setShipmentStatus(cloneDeep(shipmentStatus))
      stores.shipmentsLookupsDataStore.setLegendData([
        t("ShipmentsLookups.Shipments"),
        t("ShipmentsLookups.Lookups"),
      ])
    })
  }

  public componentWillUnmount() {
    LanguageUtils.removeListener(IListenerNameEnum.ShipmentStatusListener)
  }

  render() {
    const { t } = this.props

    const { shipmentsLookupsDataStore } = stores,
      shipmentsLookupsDataLoading = (shipmentsLookupsDataStore?.loadedDataTime || 0) === 0

    // TODO 图表多语言暂时先不搞
    stores.shipmentsLookupsDataStore.setLegendData([
      t("ShipmentsLookups.Shipments"),
      t("ShipmentsLookups.Lookups"),
    ])

    return (
      <Observer>{() => {
        return <>
          <LegacyCard.Section>
            <CasingPly show={shipmentsLookupsDataLoading}>
              <Text as={"p"} variant={"headingLg"}>
                {numeral(shipmentsLookupsDataStore?.shipments_count || 0)
                  .format("0,0")} / {numeral(shipmentsLookupsDataStore?.lookups_count || 0)
                  .format("0,0")}
              </Text>
              {/* {this.dataTable()}*/}
              <p className={styles.Chart__Subhead}>
                <Text as="span" tone="subdued" variant={"bodySm"} fontWeight={"semibold"}>{t("ShipmentsLookups.subTitle")}</Text>
              </p>
            </CasingPly>

            <SpinnerContainer
              loading={shipmentsLookupsDataLoading}
              spinnerClassName={["relative", "-top-20"]}
            >
              <DynamicLoadError errorMsg={"Network error, unable to load charts"}>
                <React.Suspense fallback={
                  <SpinnerContainer loading={!shipmentsLookupsDataLoading}>
                    <div className={styles.CardSection__ReactECharts} />
                  </SpinnerContainer>
                }
                >
                  <DashboardLineChart className={styles.CardSection__ReactECharts}
                    option={shipmentsLookupsDataStore?.options}
                  />
                </React.Suspense>
              </DynamicLoadError>
            </SpinnerContainer>
          </LegacyCard.Section>

          <div className={"mt-1 mb-2"}>
            <Divider />
          </div>

          <LegacyCard.Section>
            <p className={"mb-1"}>
              <Text as="span" tone={"subdued"} fontWeight={"medium"}>{t("ShipmentsLookups.shipmentStatus")}</Text>
            </p>
            <CasingPly show={shipmentsLookupsDataLoading}>
              <AnalyticList
                widthList={["53%", "30%", "30%"]}
                data={
                  stores.shipmentsLookupsDataStore.shipment_status
                    .filter(item => ShipmentsStatus[item.status])
                    .map(item => {
                      const target = ShipmentsStatus[item.status]
                      // @ts-ignore
                      const tagName = t(`ShipmentStatus.${target.translationKey}`, {
                        ns: "common",
                        defaultValue: target.name,
                      })

                      return {
                        values: [tagName as string, item.quantity, percentNumber(item.percentage || "-")],
                        color: target.color,
                      }
                    })
                }
              />
            </CasingPly>
          </LegacyCard.Section>
        </>
      }
      }</Observer>
    )
  }
}

export default withTranslation("dashboard")(ShipmentsLookupsChart)
