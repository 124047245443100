import { ContentState, EditorState } from "draft-js"
import htmlToDraft from "html-to-draftjs"

/**
 * 字符串转EditorState
 * @param value
 */
export const stringToEditorState = (value: string) => {
  const contentBlock = htmlToDraft(value)

  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    )

    return EditorState.createWithContent(contentState)
  } else {
    return null
  }
}

/**
 * 检查是否有实际文本
 * @param editorState
 */
export function isDraftEditorEmpty(editorState: EditorState) {
  const content = editorState.getCurrentContent();
  return !content.hasText(); // 检查是否有实际文本
}
