import { Badge } from "@shopify/polaris"
import classNames from "classnames"
import React, { CSSProperties } from "react"

import styles from "./HotBadge.module.scss"
interface IProps {
  children?: string;
  className?: string[]
  style?: CSSProperties
}

export default function HotBadge (props: IProps) {
  const __styles = {
    "--p-color-bg-fill-transparent-secondary": "#f36a5a",
    "--p-color-text-secondary": "#ffffff",
    "--p-text-body-sm-font-weight": 550,
  }

  return (
    <div className={classNames(styles.hotBadge, props.className || [])}
      style={{ ...__styles, ...props.style }}
    >
      <Badge>{props.children || "Hot"}</Badge>
    </div>
  )
}
