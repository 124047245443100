import React from "react"

import { cornerRadiusEnum, displayAlignEnum, languageList, moduleType } from "@/pages/Settings/EmailEdit/type"
import EmailEditStore from "@/stores/emailEdit"
import ModulesStore from "@/stores/emailEdit/modules"

// 组合语言列表数组
const getLanguagesList = (locales: string[], defaultLocale: string): { name: string, value: string, isDefault: boolean }[] => {
  return locales.map(locale => {
    const target = languageList.find(item => item.value === locale) as { name: string, value: string }

    return { ...target, isDefault: locale === defaultLocale }  // isDefault 是否为店铺默认语言
  })
}
// Social 列表的生成
const SocialItems = (list: { name: string, value: string }[], color: string) => {
  const BASEURL = "https://cdn.parcelpanel.com/front-end/2023/social-icon-3x"
  let template = ""

  list.forEach(item => {
    if (item.value) {
      template += `<div style="width: 36px;max-width: 36px;height: 36px;max-height: 36px;display: inline-block;margin: 8px">
        <a href="${item.value}" target="_blank" style="text-decoration: none; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
          <img width="36" height="36" src="${BASEURL}/${color}/${item.name}.png" style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -ms-interpolation-mode: bicubic; height: auto; outline: none; text-decoration: none; border-width: 0;">
        </a>
      </div>`
    }
  })
  return template
}

export type exportEmailContentType = {
  activeModule: moduleType | undefined;
  moduleConfig: ModulesStore;
  mainConfig: EmailEditStore
  defaultMount: string
  isRemovedBranding?: boolean
}
const exportEmailContent = (props: exportEmailContentType) => {
  const { activeModule, moduleConfig, mainConfig, isRemovedBranding, defaultMount } = props
  const { header, display, colors, social, footer } = moduleConfig.config
  const { ot, si, pr, du, text } = moduleConfig.content[mainConfig.orderStatus]
  const { status } = mainConfig.config.content[mainConfig.orderStatus]

  const styles: Record<string, string> = {
    // 全局对齐
    common_align: display.align === displayAlignEnum.CENTER ? "center" : display.align === displayAlignEnum.RIGHT ? "right" : "left",
    common_block_img_align: display.align === displayAlignEnum.CENTER ? "0 auto" : display.align === displayAlignEnum.RIGHT ? "0 0 0 auto" : "auto 0 0",
    common_radius: display.corner_radius === cornerRadiusEnum.NONE ? "0" : display.corner_radius === cornerRadiusEnum.STANDARD ? "8px" : "16px",


    // email color
    common_background_color: colors.email_bg,

    // header
    header_store_name: header.store_name,
    header_logo_url: header.logo_url,
    header_logo_width: String(header.logo_width),
    header_color: colors.header_store_name,
    header_bg_color: colors.header_bg,

    body_bg_color: colors.body_bg,
    body_header_color: colors.body_heading,
    body_desc_color: colors.body_desc,
    body_btn_color: colors.body_btn,
    body_btn_bg_color: colors.body_btn_bg,

    // order_tracking
    order_tracking_header: ot.heading,
    order_tracking_desc: ot.desc,
    order_tracking_btn: ot.btn_text,
    // text
    text_content: text,

    // shipment
    shipment_header: si.heading,
    shipment_items_template: "{{shipment_items}}",
    shipment_items: `
          <div style="padding:20px 0 0;margin: 0;font-family:inherit;">
            <a style="text-decoration:none;color: inherit;font-family:inherit;" target="_blank">
              <div style="font-family:inherit;padding: 0;margin: 0; text-align: {{shipment_align}};">
                <div style="float: {{shipment_align}};width: 60px;height:60px;overflow: hidden;border: 1px solid #EBEBEB;border-radius: 4px;">
                  <img style="width: 100%;height:100%;" width="60" height="60" src="https://cdn.parcelpanel.com/assets/admin/custom/images/pro1.png">
                </div>
                <div style="font-size: 16px;font-weight: 400;line-height: 20px;padding: {{shipment_items_margin}};margin: 0;min-height: 60px;font-family:inherit;">
                  <div style="padding: 0 0 8px 0;margin: 0;word-break: break-word;font-family:inherit;">x1 Test product just for preview</div>
                  <div style="font-family:inherit;padding:0;margin: 0;font-size: 14px;">L / Black</div>
                </div>
              </div>
            </a>
          </div>
          <div style="padding:20px 0 0;margin: 0;font-family:inherit;">
            <a style="text-decoration:none;color: inherit;font-family:inherit;" target="_blank">
            <div style="font-family:inherit;padding: 0;margin: 0; text-align: {{shipment_align}};">
              <div style="float: {{shipment_align}};width: 60px;height:60px;overflow: hidden;border: 1px solid #EBEBEB;border-radius: 4px;">
                <img style="width: 100%;height:100%;" width="60" height="60" src="https://cdn.parcelpanel.com/assets/admin/custom/images/pro2.png">
              </div>
              <div style="font-size: 16px;font-weight: 400;line-height: 20px;padding: {{shipment_items_margin}};margin: 0;min-height: 60px;font-family:inherit;">
                <div style="padding: 0 0 8px 0;margin: 0;word-break: break-word;font-family:inherit;">x1 Test product just for preview</div>
                <div style="font-family:inherit;padding:0;margin: 0;font-size: 14px;">Blue / XXXXL</div>
              </div>
            </div>
            </a>
          </div>
`,
    // ProductRecommendation
    product_recommendation_bg_color: colors.prod_rec_bg,
    product_recommendation_heading_color: colors.prod_rec_heading,
    product_recommendation_desc_color: colors.prod_rec_desc,
    product_recommendation_heading: pr.heading,
    product_recommendation_logic: String(pr.logic),
    product_recommendation_coll: String(pr.coll),
    discount_upsell_bg_color: colors.du_bg,
    discount_upsell_heading_color: colors.du_heading,
    discount_upsell_desc_color: colors.du_desc,
    discount_upsell_btn_bg_color: colors.du_btn_bg,
    discount_upsell_btn_color: colors.du_btn,
    discount_upsell_heading: du.heading,
    discount_upsell_discount_code: du.discount_code,
    discount_upsell_desc: du.desc,
    discount_upsell_btn_text: du.btn_text,
    discount_upsell_go_to_url: du.go_to_url,

    shipment_align: display.align === displayAlignEnum.RIGHT ? "right" : "left",
    shipment_items_margin: display.align === displayAlignEnum.RIGHT ? "0 80px 0 0px" : "0 0 0 80px",


    // social
    social_template: `
      <div style="background-color: {{footer_bg_color}};padding: 24px 12px 12px 12px;">
         <div style="margin: 0 auto;max-width: 100%;text-align: {{common_align}};">
          ${SocialItems(social.social_media, social.icon_color)}
         </div>
      </div>
    `,
    // footer
    footer_bg_color: colors.footer_bg,
    footer_text_color: colors.footer_text,
    footer_link_color: colors.footer_link,
    footer_address: footer.address,
    footer_btn_text: footer.btn_text,
    footer_unsubscribe_text: footer.unsubscribe_text,
  }

  const HeaderLogo = `<img style="padding:0;margin:0;width: ${header.logo_width}%" width="${header.logo_width}%" src="${header.logo_url}" alt="">`
  const HeaderTitle = `<h1 style="font-size:30px;line-height: 45px;margin:0;word-break: break-all;font-family:inherit;font-weight: 600;color: {{header_color}}">${header.store_name}</h1>`

  const template: Record<string, string> = {
    default_logoTitle: `<a href="${header.go_to_url}" style="text-decoration: none;" target="_blank">${header.logo_url ? HeaderLogo : HeaderTitle}</a>`,
    discount: `<div style="width: 100%;text-align: {{common_align}};background: #f3f3f3;color: #000000 !important;font-size: 16px;line-height: 24px;font-weight: 400;padding: 0;margin: 0;">
  <div style="max-width: 600px;margin: 0 auto;padding:32px 0 60px;font-family:inherit;background-color: ${colors.du_bg};"><div style="padding:0 20px;margin: 0;font-family:inherit;">
    <h2 style="font-size: 24px;line-height: 28px;padding: 0 0 8px;margin: 0;font-family:inherit;font-weight: 500;color: ${colors.du_heading}">${du.heading}</h2>
    <p style="margin: 0 0 20px;line-height: 24px;word-wrap: break-word;hyphens: auto;color: ${colors.du_desc};white-space: pre-wrap;">${du.desc.replaceAll("\n", "<br/>")}</p>
    ${du.discount_code === "" ? "" : `
    <div style="padding: 0;margin: 0;font-family:inherit;background: #fff;border: 3px dashed ${colors.du_btn_bg};border-radius: {{common_radius}};overflow: hidden;">
    <span style="text-align:center;font-family:inherit;font-size: 18px;font-weight: 600;line-height: 26px;padding: 6px 0;margin: 0;background: #fff;text-decoration: unset;color: inherit;width: 100%;display: inline-block;">
    ${du.discount_code}
    </span>
    </div>`}
    <div style="padding: 0;margin: 8px 0 0;font-family:inherit;">
        <a href="{{discount_upsell_go_to_url}}"
           target="_blank"
           style="text-align:center;font-family:inherit;font-size: 18px;font-weight: 600;line-height: 20px;padding: 12px 0;margin: 0;background: ${colors.du_btn_bg};color: ${colors.du_btn};text-decoration: unset;width: 100%;display: inline-block;border-radius: ${styles.common_radius}">
           ${du.btn_text}
        </a>
    </div>
    </div>
  </div>
</div>`,
    product_recommendation: `
    <div style="background-color: ${colors.prod_rec_bg};padding: 30px 4px 60px 4px;">
      <div style="text-align: ${styles.common_align};font-weight: 500;padding-right: 16px;padding-left: 16px;font-size: 24px;line-height: 36px;color: ${colors.prod_rec_heading};word-wrap: break-word;">${pr.heading}</div>
      <table cellspacing="0" cellpadding="0" border="0" style="margin: 0;padding: 0;width:100%;border-spacing: 0;max-width:600px;">
      <tr style="vertical-align: top;">
          <td style="padding-right: 16px;padding-left: 16px;padding-top: 32px;width: 50%;">
              <img style="display:block;width: 100%;" src="https://cdn.parcelpanel.com/assets/admin/custom/images/prod-canauimxdw.png" width="100%">
              <div style="margin-top: 16px;font-weight: 400;font-size: 16px;line-height: 20px;text-decoration-line: underline;color: ${colors.prod_rec_desc};text-align: {{common_align}};">Test product just for preview</div>
              <div style="margin-top: 8px;font-size: 16px;line-height: 20px;color: ${colors.prod_rec_desc};text-align: {{common_align}};">${defaultMount}</div>
          </td>
          <td style="padding-right: 16px;padding-left: 16px;padding-top: 32px;width: 50%;">
              <img style="display:block;width: 100%;" src="https://cdn.parcelpanel.com/assets/admin/custom/images/prod-heinuq7bm0.png" width="100%">
              <div style="margin-top: 16px;font-weight: 400;font-size: 16px;line-height: 20px;text-decoration-line: underline;color: ${colors.prod_rec_desc};text-align: {{common_align}};">Test product just for preview</div>
              <div style="margin-top: 8px;font-size: 16px;line-height: 20px;color: ${colors.prod_rec_desc};text-align: {{common_align}};">${defaultMount}</div>
          </td>
      </tr>
      <tr style="vertical-align: top;">
          <td style="padding-right: 16px;padding-left: 16px;padding-top: 32px;width: 50%;">
              <img style="display:block;width: 100%;" src="https://cdn.parcelpanel.com/assets/admin/custom/images/prod-4yq5t3lyj6.png" width="100%">
              <div style="margin-top: 16px;font-weight: 400;font-size: 16px;line-height: 20px;text-decoration-line: underline;color: ${colors.prod_rec_desc};text-align: {{common_align}};">Test product just for preview</div>
              <div style="margin-top: 8px;font-size: 16px;line-height: 20px;color: ${colors.prod_rec_desc};text-align: {{common_align}};">${defaultMount}</div>
          </td>
          <td style="padding-right: 16px;padding-left: 16px;padding-top: 32px;width: 50%;">
              <img style="display:block;width: 100%;" src="https://cdn.parcelpanel.com/assets/admin/custom/images/prod-eqr6ioyf7z.png" width="100%">
              <div style="margin-top: 16px;font-weight: 400;font-size: 16px;line-height: 20px;text-decoration-line: underline;color: ${colors.prod_rec_desc};text-align: {{common_align}};">Test product just for preview</div>
              <div style="margin-top: 8px;font-size: 16px;line-height: 20px;color: ${colors.prod_rec_desc};text-align: {{common_align}};">${defaultMount}</div>
          </td>
      </tr>
      </table>
    </div>
    `,
    default_emailTitle: "{{order_tracking_header}}",
    default_customerName: "",
    default_btnCon: "{{order_tracking_btn}}",
    default_emailContent: "{{order_tracking_desc}}",
    default_proTitle: "{{shipment_header}}",
    default_proHtml: "{{shipment_items}}",
    default_btnColor: "{{colors.body_btn_bg}}",
  }

  for (const key in colors) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles[`colors.${key}`] = colors[key]
  }

  Object.assign(template, styles)

  let HTMLTemplate = mainConfig.getHTMLContent()


  for (const key in template) {
    // 不进行html节点过滤的白名单
    if (!["default_logoTitle", "discount", "product_recommendation", "shipment_items", "social_template", "default_emailTitle"].includes(key)) {
      template[key] = typeof template[key] === "string" ? template[key].replaceAll("<", "&lt;") : template[key]
    }
    if (["order_tracking_desc", "text_content", "footer_address", "footer_unsubscribe_text", "default_description"].includes(key)) {
      template[key] = template[key].replaceAll("\n", "<br/>")
    }

    HTMLTemplate = typeof HTMLTemplate === "string"
      ? HTMLTemplate.replaceAll(`{{${key}}}`, template[key])
      : HTMLTemplate
  }

  // 后端变量替换
  for (const key in mainConfig.vars) {
    HTMLTemplate = HTMLTemplate.replaceAll(`{{${key}}}`, mainConfig.vars[key])
  }

  // 2024-03-19 新增变量，进行替换
  HTMLTemplate = HTMLTemplate.replaceAll(
    "{{sha256.customer_email_order_number_remove_hash.concatenated}}",
    "80f903e8c031bff1e3e09a64059f1d5c932fac0d1969adfa61690750390ffa36",
  )

  // 2024-03-19 新增变量，进行替换
  HTMLTemplate = HTMLTemplate.replaceAll(
    "{{order_number_remove_hash}}",
    "1234",
  )


  // 设置聚焦
  if (activeModule) {
    HTMLTemplate = HTMLTemplate.replace(`id="PP-Email-${activeModule}"`, `id="PP-Email-${activeModule}" class="pp-emailModuleActive"`)
  }

  // 判断social隐藏
  if (!mainConfig.config.branding_config.social.show) {
    HTMLTemplate = HTMLTemplate.replace(/<!--Social-->(.|\n)*?<!--Social-->/g, "")
  }

  // 判断text隐藏
  if (!status.text) {
    HTMLTemplate = HTMLTemplate.replace(/<!--Text-->(.|\n)*?<!--Text-->/g, "")
  }

  // 判断Shipment items隐藏
  if (!status.si) {
    HTMLTemplate = HTMLTemplate.replace(/<!--ShipmentItems-->(.|\n)*?<!--ShipmentItems-->/g, "")
  }

  // 判断ProductRecommendation隐藏
  if (!status.pr) {
    HTMLTemplate = HTMLTemplate.replace(/<!--ProductRecommendation-->(.|\n)*?<!--ProductRecommendation-->/g, "")
  }

  // 判断ProductRecommendation隐藏
  if (!status.du) {
    HTMLTemplate = HTMLTemplate.replace(/<!--DiscountUpsell-->(.|\n)*?<!--DiscountUpsell-->/g, "")
  }

  if (!isRemovedBranding && isRemovedBranding !== undefined) {
    HTMLTemplate += `<div style="width:100%;font-family: inherit;background-color: {{colors.email_bg}};">
      <div style="max-width:600px;margin:0 auto;text-align: right;font-size: 12px;line-height: 20px;padding: 10px 20px;color: rgba(17, 17, 17, 0.5);">
        <a class="pp_a__hover-link" style="color: rgba(17, 17, 17, 0.5);text-decoration: none" href="https://www.parcelpanel.com/" target="_blank" rel="noreferrer">Powered by ParcelPanel</a>
        <img style="height:8px;margin: 0 4px;" src="https://cdn.parcelpanel.com/images/line.png" alt="line" />
        <a class="pp_a__hover-link" href="https://www.channelwill.com/" target="_blank" rel="noreferrer">
          <img style="width: 12px;height: 12px;vertical-align: -1px;" src="https://cdn.parcelpanel.com/images/channelwill-gary.png" alt="channelwill">
        </a>
      </div>
    </div>`
  }

  // 去除代码中的HTML注释、CSS隐藏内容和脚本
  const regex = /<style[\s\S]*?<\/style>|<script[\s\S]*?<\/script>|<!--[\s\S]*?-->/g

  HTMLTemplate = HTMLTemplate.replace(regex, "")

  const rereplace = {
    shipment_items: styles.shipment_items,
    discount: template.discount,
    product_recommendation: styles.product_recommendation_template,
  }

  for (const key in rereplace) {
    HTMLTemplate = HTMLTemplate.replaceAll(`{{${key}}}`, template[key])
  }

  HTMLTemplate = HTMLTemplate.replaceAll("{{colors.email_bg}}", colors.email_bg)
  HTMLTemplate = HTMLTemplate.replaceAll("target=\"_blank\" href=\"{{unsubscribe_link}}\"", "")

  return HTMLTemplate
}

export {
  exportEmailContent,
  getLanguagesList,
}
