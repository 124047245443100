import { BlockStack, EmptySearchResult, Modal, SkeletonBodyText, Text } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"

import Delivered from "@/assets/svg/Delivered"
import Exception from "@/assets/svg/Exception"
import FailedAttempt from "@/assets/svg/FailedAttempt"
import InfoReceived from "@/assets/svg/InfoReceived"
import InTransit from "@/assets/svg/InTransit"
import OutForDelivery from "@/assets/svg/OutForDelivery"
import TimelineItemList from "@/pages/Orders/components/NotificationHistoryModal/TimelineItemList"

import styles from "./NotificationHistoryModal.module.scss"

export interface Item {
  id: number
  msg: string
  created_at: string
  to_email: string
  tpl_sort: string
}

interface INotificationHistoryModalProps {
  open: boolean
  loading: boolean
  items: Item[]

  onClose(): void
}

export function NotificationHistoryModal({
  open,
  onClose,
  loading,
  items,
}: INotificationHistoryModalProps) {
  const { t } = useTranslation("orders", { keyPrefix: "NotificationHistoryModal" })
  const { t: tCommon } = useTranslation("common")

  const TPL_SORT_MAP_ICON: { [k: string]: any } = {
    delivered: Delivered,
    exception: Exception,
    failed_attempt: FailedAttempt,
    in_transit: InTransit,
    info_received: InfoReceived,
    pickup: OutForDelivery,
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("title")}
      secondaryActions={[{ content: tCommon("Close"), onAction: onClose }]}
      sectioned
    >
      <BlockStack
        gap="300"
      >
        <p>{t("p")}</p>
        {
          loading ? (
            <>
              <div><SkeletonBodyText lines={2} /></div>
              <div><SkeletonBodyText lines={2} /></div>
              <div><SkeletonBodyText lines={2} /></div>
            </>
          ) : items.length ? (<>
            <TimelineItemList
              items={
                items.map(({ created_at, id, msg, to_email, tpl_sort }) => {
                  const IconComponent = TPL_SORT_MAP_ICON[tpl_sort]

                  return (
                    {
                      key: id,
                      dot: (
                        <IconComponent
                          width="17px"
                          height="17px"
                        />
                      ),
                      children: (
                        <div>
                          <Text
                            as="p"
                            tone="subdued"
                          >{created_at}</Text>
                          <p>{msg}</p>
                        </div>
                      ),
                    }
                  )
                })
              }
            />
          </>
          ) : (
            <div style={{ margin: "4px 0 -16px" }}>
              <EmptySearchResult title={t("EmptyResult")} withIllustration />
            </div>
          )
        }
      </BlockStack>
    </Modal>
  )
}
