import {
  BlockStack,
  Checkbox,
  DatePicker, Icon, InlineStack,
  Popover,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText, TextField,
} from "@shopify/polaris"
import { CalendarIcon } from "@shopify/polaris-icons"
import { useDebounce, useDebounceFn } from "ahooks"
import dayjs from "dayjs"
import { clamp, isEmpty } from "lodash-es"
import React, { CSSProperties, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import isDate from "validator/lib/isDate"

import { DayTimeEnum, UpdateStatusItemProps } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"

import styles from "./UpdateStatus.module.scss"

interface Range {
  start: Date;
  end: Date;
}

export default React.memo(function UpdateStatusItem(
  {
    id, title, subTitle, index,
    checked, disabled, loading,
    isSelected, children,
    timestamp,
    onChange, onClick, onDatetimeChange,
  }: UpdateStatusItemProps,
) {
  const { t } = useTranslation("common")

  const [dateInfo, setDateInfo] = useState({
    date: timestamp ? dayjs.unix(timestamp).tz()
      .format("YYYY-MM-DD") : "",
    hour: timestamp ? dayjs.unix(timestamp).tz()
      .format("HH") : "",
    min: timestamp ? dayjs.unix(timestamp).tz()
      .format("mm") : "",
    tag: timestamp ? dayjs.unix(timestamp).tz()
      .format("a") : "",
  })

  const datetime = useMemo(() => dayjs.unix(timestamp).tz(), [timestamp])
  const [inputDate, setInputDate] = useState<string>(datetime.format("YYYY-MM-DD"))
  const [inputDateError, setInputDateError] = useState<string | boolean>(false)

  const initialSelectorDatetime = useMemo(() => ({
    day: datetime.day(),
    dayTime: datetime.format("a") as DayTimeEnum,
    hour: datetime.format("h"),
    minute: datetime.minute().toString(),
    month: datetime.month(),
    year: datetime.year(),
  }), [datetime])

  const [displayDatePick, setDisplayDatePick] = useState(false)
  const [inputDatetime, setInputDatetime] = useState<typeof initialSelectorDatetime>(initialSelectorDatetime)

  useEffect(() => {
    if (!stores.commonStore.contextualSaveBar.show) {
      setInputDatetime(initialSelectorDatetime)
      setInputDate(datetime.format("YYYY-MM-DD"))
      setInputDateError(false)
    }
  }, [stores.commonStore.contextualSaveBar.show, initialSelectorDatetime, datetime])

  const handleInputDatetime = useCallback(handleInputDatetimeFunc, [datetime, index, inputDatetime, onDatetimeChange])

  const setSelectedDates = useCallback((date: Range) => {
    setInputDateError(false)
    const day = dayjs(date.start).format("YYYY-MM-DD")
    const tempDate = `${day} ${dateInfo.hour}:${dateInfo.min}:00`
    const $dayjs = dayjs.tz(tempDate)

    setInputDate(day)
    onDatetimeChange(index, $dayjs.unix())
    setDisplayDatePick(false)
  }, [dateInfo.hour, dateInfo.min, index, onDatetimeChange])

  const handleMonthChange = useCallback((month: number, year: number) => {
    handleInputDatetime({ month, year })
  }, [handleInputDatetime])

  const UpdateStatusItemTextField = useMemo(() => {
    const datePickActivator = (
      <TextField
        prefix={<Icon source={CalendarIcon} />}
        error={inputDateError}
        value={inputDate}
        onFocus={() => setDisplayDatePick(true)}
        onBlur={() => {
          setInputDateError(false)
          const dateFormat = "YYYY-MM-DD"
          const currentDate = dayjs(inputDate),
            currentDateText = currentDate.format(dateFormat)

          if (isDate(currentDateText, { format: dateFormat })) {
            if (inputDate !== datetime.format(dateFormat)) {
              handleMonthChange(currentDate.month(), currentDate.year())
              setSelectedDates({ end: currentDate.toDate(), start: currentDate.toDate() })
            }
          } else {
            setInputDateError(t("MatchFormatTip", { replace: { format: "YYYY-MM-DD" } }))
          }
        }}
        placeholder="YYYY-MM-DD"
        onChange={setInputDate}
        labelHidden label="" autoComplete="false"
      />
    )

    const dateMarkup = (
      <Popover
        autofocusTarget="none"
        active={displayDatePick}
        activator={datePickActivator}
        onClose={() => setDisplayDatePick(false)}
        ariaHaspopup={displayDatePick}
        sectioned
      >
        <DatePicker
          month={inputDatetime.month}
          year={inputDatetime.year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={datetime.toDate()}
        />
      </Popover>
    )

    return (
      <div className={styles.UpdateStatus}>
        <InlineStack gap={"400"}>
          <div className={styles.UpdateStatus__DatePick}>{dateMarkup}</div>

          <InlineStack gap={"200"} wrap={false}>
            <div className={styles.UpdateStatus__MTextField}>
              <TextField
                labelHidden
                label="Hour"
                type="number"
                value={inputDatetime.hour}
                onChange={hour => {
                  if (isEmpty(hour)) {
                    setInputDatetime({ ...inputDatetime, hour })
                    return
                  }
                  handleInputDatetime({ dayTime: inputDatetime.dayTime, hour })
                }}
                autoComplete="off"
              />
            </div>

            <div className={styles.UpdateStatus__MTextField}>
              <TextField
                labelHidden
                label="Minute"
                type="number"
                value={inputDatetime.minute}
                onChange={minute => {
                  if (isEmpty(minute)) {
                    setInputDatetime({ ...inputDatetime, minute })
                    return
                  }
                  handleInputDatetime({ minute })
                }}
                autoComplete="off"
              />
            </div>

            <div className={styles.UpdateStatus__MTextField}>
              <Select
                labelHidden
                label="Select AM, PM"
                options={[DayTimeEnum.AM, DayTimeEnum.PM]}
                value={inputDatetime.dayTime}
                onChange={(selected: DayTimeEnum) => {
                  handleInputDatetime({ dayTime: selected, hour: inputDatetime.hour })
                }}
              />
            </div>
          </InlineStack>
        </InlineStack>
      </div>
    )
  }, [timestamp, datetime, displayDatePick, inputDatetime, inputDate, inputDateError, handleInputDatetime, handleMonthChange, setSelectedDates, t])

  const boxStyle: CSSProperties = {
    background: "var(--p-color-bg-surface)",
    minHeight: "100%",
    overflowX: "clip",
    overflowY: "clip",
    paddingBlockStart: "var(--p-space-300)",
    paddingBlockEnd: "var(--p-space-300)",
    paddingInlineStart: "var(--p-space-300)",
    paddingInlineEnd: "var(--p-space-300)",
  }

  const { run: debouncedOnclick } = useDebounceFn((e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const targetTagName = target.tagName.toLowerCase()

    if (disabled) {
      return
    }

    // console.log("====== ", target, target.className)

    const eventMap = [
      // Checkbox help text
      targetTagName === "span" && target.className.includes("Polaris-Text--subdued"),

      // Other element
      targetTagName === "div",
    ]

    if (eventMap.includes(true) && checked) {
      e.stopPropagation()

      // 展开/收起面板，如果没有选中则选中
      onClick({
        currentChecked: checked,
        currentIndex: index,
        currentSelected: isSelected,
      })

      return
    }

    onChange(!checked, id)

    e.stopPropagation()
  }, { wait: 10 })

  return (
    <div
      style={boxStyle}
      className="Polaris-Box cursor-pointer"
      onClick={debouncedOnclick}
    >
      {loading ? (
        <BlockStack gap={"200"}>
          <SkeletonDisplayText size="medium" />
          <SkeletonBodyText lines={1} />
        </BlockStack>
      ) : (
        <>
          <Checkbox
            id={id}
            label={title}
            helpText={subTitle}
            checked={checked}
            disabled={disabled}
          />
          {isSelected && <div style={{ paddingTop: "var(--p-space-200)" }} onClick={(e) => e.stopPropagation()}>{UpdateStatusItemTextField}</div>}
          {children}
        </>
      )}
    </div>
  )

  function handleInputDatetimeFunc(value: Partial<typeof initialSelectorDatetime>) {
    if (value?.dayTime && (value?.hour || "0" === value?.hour)) {
      switch (parseInt(value.hour)) {
        case 0:
          value.hour = "12"
          break
        case 13:
          value.hour = "1"
          break
        default:
          value.hour = clamp(parseInt(value.hour), 1, 12).toString()
      }
    }

    if (value?.minute || "0" === value.minute) {
      switch (parseInt(value.minute)) {
        case 60:
          value.minute = "0"
          break
        default:
          value.minute = clamp(parseInt(value.minute), 0, 59).toString()
      }
    }

    const mergeDatetime = { ...inputDatetime, ...value }

    if (isEmpty(mergeDatetime.hour)) {
      mergeDatetime.hour = "0"
    }

    if (isEmpty(mergeDatetime.minute)) {
      mergeDatetime.minute = "0"
    }

    const mHour = parseInt(mergeDatetime.hour)
    const mMinute = parseInt(mergeDatetime.minute)

    let _dt = datetime.hour(mHour).minute(mMinute)
    const { dayTime } = mergeDatetime

    if (dayTime === "am") {
      if (_dt.hour() > 12) {
        _dt = _dt.subtract(12, "hour")
      } else if (mHour === 12) {
        _dt = _dt.hour(0)
      }
    } else if (dayTime === "pm") {
      if (mHour <= 11) {
        _dt = _dt.hour(mHour + 12)
      } else if (mHour === 12) {
        _dt = _dt.hour(12)
      }
    }

    setDateInfo({
      date: dateInfo.date,
      hour: String(_dt.hour()),
      min: String(mMinute),
      tag: dayTime,
    })

    const newTimestamp = _dt.unix()

    if (datetime.unix() !== newTimestamp) {
      onDatetimeChange(index, newTimestamp)
    }

    setInputDatetime(mergeDatetime)
  }
})
