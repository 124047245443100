import { ActionList, Button, Popover } from "@shopify/polaris"
import { Observer } from "mobx-react"
import { useCallback, useState } from "react"

import { SupportLanguages } from "@/i18n/SupportLanguages"
import stores from "@/stores"

const LanguageSelect = () => {
  const [active, setActive] = useState(false)

  const toggleActive = useCallback(() => setActive((active) => !active), [])
  const { commonStore } = stores

  const selectOption = commonStore.languageItems.map(item => {
    return {
      content: item.content,
      active: item.id === commonStore.languageActiveCode,
      onAction: () => {
        commonStore.setActiveLanguage(item.id as SupportLanguages)
        setActive(false)
      },
    }
  })

  return <Observer>{() => {
    return <div className={"flex relative top-[-2px] mr-[-4px]"}>
      <Popover
        active={active}
        activator={<Button onClick={toggleActive} disclosure>
          {selectOption.find(item => item.active)?.content ?? "English"}
        </Button>}
        autofocusTarget="first-node"
        onClose={toggleActive}
      >
        <ActionList
          actionRole="menuitem"
          items={selectOption}
        />
      </Popover>
    </div>
  }}</Observer>
}

export default LanguageSelect
