import { FooterHelp, Modal, Page } from "@shopify/polaris"
import { ExportIcon, LockIcon } from "@shopify/polaris-icons"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { Container, Footer } from "@/component"
import Tabs from "@/component/Tabs/index"
import permissionRules from "@/config/permission-rules"
import Permission from "@/enums/permission"
import authenticate from "@/utils/authenticate"
import { exportPDF } from "@/utils/exportPDF"
import toast from "@/utils/toast"

export default function Analytics () {
  const { t } = useTranslation("analytics")
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [tabIndex, setTabIndex] = useState(0)
  const [exportModal, setExportModal] = useState(false)

  const tabList = [
    { content: t("Shipments.title"), id: "/analytics/shipment", key: "parcelpanel_shipments_analytics" },
    { content: t("TransitTime.title"), id: "/analytics/transit-time", key: "parcelpanel_transit_time_analytics" },
    { content: t("TrackingPage.title"), id: "/analytics/tracking-page", key: "parcelpanel_tracking_page_analytics" },
    { content: t("ShippingNotifications.title"), id: "/analytics/shipping-notifications", key: "parcelpanel_shipping_notifications_analytics" },
  ]

  const exportPdf = () => {
    exportPDF(tabList[tabIndex].key, "pp-analytics-pdf")
    setExportModal(false)
    toast(t("exportSuccessfully"), 2e3)
  }

  useEffect(() => {
    const targetPageIndex = tabList.findIndex(tab => tab.id === pathname)

    if (targetPageIndex === -1){
      setTabIndex(0)
    } else if (targetPageIndex && targetPageIndex !== tabIndex){
      setTabIndex(targetPageIndex)
    }
  }, [pathname])

  return <Container maxWidth={"1036px"} className={"mt-0"} fullWidth={false}>
    <Page
      title={t("Analytics")}
      secondaryActions={[{
        content: t("ExportPDF"),
        icon: !authenticate(permissionRules[Permission.CSVExport]) ? LockIcon : ExportIcon,
        onAction: () => setExportModal(true),
        disabled: !authenticate(permissionRules[Permission.CSVExport]),
      }]}
    >
      <Tabs
        tabs={tabList}
        activeIndex={tabIndex}
        onChange={(index:number) => {
          setTabIndex(index)
          navigate(tabList[index].id || "/analytics")
        }}
      />
      <div className={"mt-4"} id={"pp-analytics-pdf"} style={{ backgroundColor: "#f1f1f1" }}>
        <Outlet />
      </div>

      <FooterHelp>
        <Footer pathname={pathname} />
      </FooterHelp>

      <Modal
        open={exportModal}
        onClose={() => setExportModal(false)}
        title={t("ExportPDF")}
        primaryAction={{
          content: t("ExportPDF"),
          onAction: exportPdf,
        }}
        secondaryActions={[
          {
            content: t("Cancel"),
            onAction: () => setExportModal(false),
          },
        ]}
      >
        <Modal.Section>
          <p>{t("ExportPDFDesc")}</p>
        </Modal.Section>
      </Modal>
    </Page>
  </Container>
}
