import { t } from "i18next"

import {
  BaseResponse,
  CartSettingApi,
  CheckWidgetApi, ClaimList, ContractList,
  InfoApi, InsurancePointType, ListData, OverviewApi, ParamsApi, ScreenTime, SysConfigA, TransactionsData, TransactionsListApi,
  WidgetFormParams,
} from "@/pages/DeliveryGuarantee/Api/type"

import request from "../utils/request"
import { RequestMessageMiddleware } from "../utils/RequestMiddleware"

export default {

  /**
   * 获取信息
   */
  postPoint(data: { point_type: InsurancePointType }) {
    return RequestMessageMiddleware(request.post("/api/v1/point", data))
  },
  /**
   * 关闭护理效果
   */
  setCloseBreathe() {
    return RequestMessageMiddleware(request.post("/api/v1/setCloseBreathe"))
  },
  /**
   * 获取信息
   */
  getInfo() {
    return RequestMessageMiddleware(request.get<BaseResponse<InfoApi>>("/api/v1/getInfo"))
  },

  /**
   * 预览数据 /api/v1/overviewData
   */
  getOverviewData(param: ScreenTime) {
    return RequestMessageMiddleware(request.get<BaseResponse<OverviewApi>>("/api/v1/overviewData", { params: param }))
  },

  /**
   * 获取小部件配置
   */
  getCartSetting() {
    return RequestMessageMiddleware(request.get<BaseResponse<CartSettingApi>>("/api/v1/getCartSetting"))
  },


  /**
   * 关闭 banner及 modal /api/v1/setViewAuditRet
   */
  setCloseShowModalAndBanner(data: { closeType: "isShowBanner" | "isShowModal" }) {
    return RequestMessageMiddleware(request.post<BaseResponse<InfoApi>>("/api/v1/setCloseModal", data))
  },

  /**
   * 小部件设置 除去总开关 /api/v1/setSpecial
   */
  setSpecial(param: WidgetFormParams) {
    return RequestMessageMiddleware(request.post<BaseResponse<any>>("/api/v1/setSpecial", param), {
      200: t("toast.SavedSuccess", { ns: "delivery_guarantee" }),
      "*": t("toast.SavedFailed", { ns: "delivery_guarantee" }),
    })
  },
  /**
   * 设置关闭 X icon
   */
  setCloseTip(data: { closeType: "isShowTip" | "autoInstallStatus" }) {
    return RequestMessageMiddleware(request.post<BaseResponse<any>>("/api/v1/setCloseTip", data))
  },
  /**
   * 检测是否可以自动适配
   */
  checkAutoFit() {
    return RequestMessageMiddleware(request.get<BaseResponse<{ autoFit: boolean }>>("/api/v1/checkAutoFit"))
  },
  /**
   * 资质审核
   */
  auditReview() {
    return RequestMessageMiddleware(request.post<BaseResponse<{ auditRet: string }>>("/api/v1/auditReview"))
  },

  /**
   * 小部件开关开启 /api/v1/setSpecial
   */
  setWidgetOn() {
    return RequestMessageMiddleware(request.post<BaseResponse<{ auditRet: string, isEnable: boolean }>>("/api/v1/setWidgetOn"), {
      "*": t("toast.PublishedFailed", { ns: "delivery_guarantee" }),
    })
  },

  /**
   * 小部件开关 关闭 /api/v1/setWidgetOff
   */
  setWidgetOff(param: any) {
    return RequestMessageMiddleware(request.post<BaseResponse<CartSettingApi>>("/api/v1/setWidgetOff", param), {
      200: t("toast.UnpublishedSuccessfully", { ns: "delivery_guarantee" }),
      "*": t("toast.UnpublishedFailed", { ns: "delivery_guarantee" }),
    })
  },
  /**
   * 设置plus block已安装
   */
  setBlock() {
    return RequestMessageMiddleware(request.post<BaseResponse<CartSettingApi>>("/api/v1/setBlock"))
  },

  /**
   * 检测小部件主题内是否开启 /api/v1/checkWidget
   */
  detectionCheckWidget() {
    return RequestMessageMiddleware(request.get<BaseResponse<CheckWidgetApi>>("/api/v1/checkWidget"))
  },

  /**
   * 设置手动或自动发布 /api/v1/setAutoPublish
   */
  setAutoPublish(param: { autoPublic: number, password: string }) {
    return RequestMessageMiddleware(request.post<BaseResponse<ParamsApi>>("/api/v1/setAutoPublish", param), {
      200: t("SubmittedSuccessfully", { ns: "delivery_guarantee" }),
      "*": t("SubmitFailed", { ns: "delivery_guarantee" }),
    })
  },

  /**
   * 保单列表 /api/v1/contract
   */
  getContract(param: ParamsApi) {
    return RequestMessageMiddleware(request.post<BaseResponse<ListData<ContractList>>>("/api/v1/contract", param))
  },

  /**
   * 理赔列表 /api/v1/claim
   */
  getClaim(param: ParamsApi) {
    return RequestMessageMiddleware(request.post<BaseResponse<ListData<ClaimList>>>("/api/v1/claim", param))
  },

  /**
   * 保费账单列表 /api/v1/transactions
   */
  getTransactions(param: ParamsApi) {
    return RequestMessageMiddleware(request.post<BaseResponse<TransactionsData>>("/api/v1/transactions", param))
  },

  /**
   * 设置支付方式 手动/自动 /api/v1/setPayMethod
   */
  setPayMethod(param: { payNumber?: string, payMethod: number }) {
    return RequestMessageMiddleware(request.post("/api/v1/setPayMethod", param), {
      200: t("toast.SavedSuccess", { ns: "delivery_guarantee" }),
      "*": t("toast.SavedFailed", { ns: "delivery_guarantee" }),
    })
  },

  /**
   * 获取公共配置 /api/v1/getSysConfig
   */
  getSysConfig() {
    return RequestMessageMiddleware(request.get<BaseResponse<SysConfigA>>("/api/v1/getSysConfig"))
  },

  /**
   * 设置公共配置  /api/v1/setSysConfig
   */
  setSysConfig(param:any) {
    return RequestMessageMiddleware(request.post("/api/v1/setSysConfig", param))
  },

  /**
   * 获取账单Excel表格 /api/v1/downloadTransact
   */
  getDownloadTransact(billId: string) {
    return RequestMessageMiddleware(request.get<BaseResponse<{ url: string }>>("/api/v1/downloadTransact", { params: { billId } }))
  },


  /**
   * 未支付账单数量 /api/v1/transactUnpaidCount
   */
  getTransactUnpaidCount() {
    return RequestMessageMiddleware(request.get<BaseResponse<ListData<{ total: string }>>>("/api/v1/transactUnpaidCount"))
  },


}
