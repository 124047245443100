import { Observer } from "mobx-react"
import React from "react"

import stores from "@/stores"

import { NotificationHistoryModal } from "./NotificationHistoryModal"


export function NotificationHistoryModalMobx() {

  const { ordersStore } = stores

  const onClose = () => {
    ordersStore.mailRecordsModalOpen = false
  }

  return <Observer>{() => (
    <NotificationHistoryModal
      open={ordersStore.mailRecordsModalOpen}
      items={ordersStore.mailSendRecords}
      loading={ordersStore.mailSendRecordsLoading}
      onClose={onClose}
    />
  )}</Observer>

}
