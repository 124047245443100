import { ChoiceList, FormLayout, LegacyStack } from "@shopify/polaris"
import { ChoiceListProps } from "@shopify/polaris/build/ts/latest/src/components/ChoiceList/ChoiceList"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import { TextDatePicker } from "@/component"
import { BetaFeature } from "@/models/IUserInfo"
import { canIUseBetaFeature } from "@/utils/BetaFeature"

dayjs.extend(utc)
dayjs.extend(timezone)
type Choices = ChoiceListProps["choices"]

interface IDateChoiceListProps {
  choices: Choices
  selected?: string

  startingSelected: dayjs.Dayjs
  endingSelected: dayjs.Dayjs

  // disableDatesBefore: Date
  // disableDatesAfter: Date

  onChoiceListChange(selected: string): void

  onStartingChange(date: dayjs.Dayjs): void

  onEndingChange(date: dayjs.Dayjs): void

  onCustomItemSelected?(selected: boolean): void
}

const DateFormat = "YYYY-MM-DD"

export function DateChoiceList({
  // id,
  choices,
  selected,
  onChoiceListChange,
  startingSelected,
  endingSelected,
  // disableDatesBefore,
  // disableDatesAfter,
  onStartingChange,
  onEndingChange,
  onCustomItemSelected,
}: IDateChoiceListProps) {

  const [showCustom, setShowCustom] = useState(false)
  const { t } = useTranslation("orders", { keyPrefix: "DateChoiceList" })

  const getBeforeDate = (subtract: number) => dayjs().tz()
    .subtract(subtract, "day")

  const defaultDisableDatesBefore = new Date(getBeforeDate(120).format(DateFormat))

  const disableDatesBefore = canIUseBetaFeature(BetaFeature.SELECT_ALL_DATE)
    ? undefined
    : defaultDisableDatesBefore
  const disableDatesAfter = new Date(dayjs().tz()
    .startOf("d")
    .format(DateFormat))

  const handleChoiceListChange = useCallback(
    (selected: string[]) => {
      // 交互体验：选择了 Custom 选项时还需要再选择日期才会触发列表筛选
      if ("Custom" !== selected[0]) {
        // 选择了非 Custom 选项才向上传递，
        // 不然会出现 appliedFilters 显示了日期范围的标签，却没更新列表的现象
        setShowCustom(false)
        onChoiceListChange(selected[0])
        // 通知父组件是否选中 `Custom` 选项
        onCustomItemSelected?.(false)
      } else {
        setShowCustom(true)
        // 通知父组件是否选中 `Custom` 选项，因为 `Custom` 选项需要加载两个日期选择器，
        // 要刷新一下父组件才能使 `Collapsible` 组件重新计算 `maxHeight` 属性
        onCustomItemSelected?.(true)
      }
    },
    [onChoiceListChange],
  )

  return (
    <>
      <ChoiceList
        title={null}
        choices={choices}
        selected={showCustom ? ["Custom"] : selected ? [selected] : []}
        onChange={handleChoiceListChange}
      />
      {
        ("Custom" === selected || showCustom) && (
          <FormLayout>
            <LegacyStack vertical>
              <TextDatePicker
                textFormat="YYYY-MM-DD"
                label={t("Starting")}
                selected={startingSelected}
                disableDatesBefore={disableDatesBefore}
                disableDatesAfter={disableDatesAfter}
                onChange={(date) => {
                  "Custom" !== selected && onChoiceListChange("Custom")
                  onStartingChange(date)
                }}
              />
              <TextDatePicker
                textFormat="YYYY-MM-DD"
                label={t("Ending")}
                selected={endingSelected}
                disableDatesBefore={disableDatesBefore}
                disableDatesAfter={disableDatesAfter}
                onChange={(date) => {
                  "Custom" !== selected && onChoiceListChange("Custom")
                  onEndingChange(date)
                }}
              />
            </LegacyStack>
          </FormLayout>
        )
      }
    </>
  )
}
