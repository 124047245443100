import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Observer } from "mobx-react"
import React from "react"
import Slider from "react-slick"

import settingsApi from "@/api/settings"
import { MarketingPicture } from "@/component/ImageBanner/MarketingPicture"
import { BannerEnum } from "@/models/IUserInfo"
import ChannelwillBundle from "@/pages/Dashboard/components/MarketingCarousel/ChannelwillBundle"
import MarketingChangePlan from "@/pages/Dashboard/components/MarketingCarousel/MarketingChangePlan"
import Api from "@/pages/DeliveryGuarantee/Api"
import stores from "@/stores"

import styles from "./index.module.scss"

const MarketingCarousel = () => {
  const { userInfoSore } = stores

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    arrows: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    touchMove: false,
  }

  return <Observer>{() => {
    const { banner_data, banner_discount_v2024_04, is_pi_active, is_pi_eligible } = userInfoSore.data
    const banner = banner_data.find(item => item.id === 28692)
    const showChannelwillBundle = banner && banner.display
    const showMarketingChangePlan = banner_discount_v2024_04.dashboard


    const isBanner2 = is_pi_eligible && !is_pi_active

    const bannerList = [
      // userInfoSore.isShowBanner(BannerEnum.MARKING_PROTECTION_BANNER) && (
      //   <MarketingPicture
      //     show
      //     src={isBanner2 ? 'https://cdn.parcelpanel.com/insurance/admin/protection2.png' : 'https://cdn.parcelpanel.com/insurance/admin/protection1.png'}
      //     onClose={() => {
      //       userInfoSore.closeBannerById(BannerEnum.MARKING_PROTECTION_BANNER)
      //     }}
      //     to={"/protection"}
      //     onLinkClick={() => {
      //       Api.postPoint({
      //         point_type: isBanner2 ? "banner2" : "banner1"
      //       })
      //     }}
      //   />
      // ),
      // showChannelwillBundle && <ChannelwillBundle />,
      showMarketingChangePlan && <MarketingChangePlan />,
    ]
    const showBannerList = bannerList.filter(item => !!item)

    if (!showBannerList.length) return null

    if (showBannerList.length === 1) return (showBannerList[0] as any)


    return <div className={styles.MarketingCarousel}>
      {
        showBannerList.length > 1
          ? <div className="slider-container">
            <Slider {...settings}>
              {showBannerList}
            </Slider>
          </div>
          : <>
            {(showBannerList[0] as any)}
          </>
      }
    </div>
  }}</Observer>

}

export default MarketingCarousel
