import dayjs from "dayjs"
import i18n from "i18next"
import { get, isNaN, uniq } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"

import OrdersAPI from "@/api/orders"
import { ITrackingResultStateData } from "@/api/TP2/tracking-info.d"
import { OptionListDragProps } from "@/component/OptionListDrag"
import { IOrders, TableItem, UpdateOrderStatusType } from "@/models/IOrders"
import { Item as MailLSendRecordItem, MailRecordData } from "@/pages/Orders/components"
import { QueryParamsType } from "@/pages/Orders/types"
import { UpdateStatusData } from "@/pages/Orders/UpdateStatus/types"
import stores from "@/stores"


export default class OrdersStore implements IOrders {
  @observable public loadedDataTime = 0
  @observable public tableData: TableItem[] = []
  @observable public itemsCount: number = 0
  @observable public queryParams: QueryParamsType = {}

  // 查看订单详情
  @observable public orderInfoItem?: TableItem// 该订单的谁信息
  @observable public orderInfoShow: boolean = false// 是否显示订单详情信息弹窗
  @observable public orderInfoData: ITrackingResultStateData | undefined// 订单详情信息数据
  @observable public orderInfoDataLoadedTime: number = 0// 订单详情数据加载时间

  @observable public tableColumnSelected: string[] = []
  @observable public tableColumnChoices: OptionListDragProps["choices"] = []

  @observable public mailRecordsModalOpen: boolean = false
  @observable public mailSendRecordsLoading: boolean = false
  @observable public mailSendRecords: MailLSendRecordItem[] = []

  // 更新订单状态
  @observable public updateOrderStatus: UpdateOrderStatusType = {
    loading: true,
  }

  constructor() {
    makeObservable(this)
  }

  @computed
  get orderInfoTransitDays(): string {
    // 处理单复数
    const handleSP = (number: string) => {
      const _number = parseInt(number)

      if (isNaN(_number) || 0 === _number) {
        return "-"
      }

      return `${_number} ${_number === 1 ? "day" : "days"}`
    }

    return handleSP(get(stores, "ordersStore.orderInfoData.transitDays", "-"))
  }

  @computed
  get autoUpdateStatusOrder(): boolean {
    return this.updateOrderStatus.data?.auto ?? true
  }

  /**
   * 设置加载完成时间
   * @param time
   */
  @action.bound
  public setLoadedDataTime(time: number): void {
    this.loadedDataTime = time
  }

  @action.bound
  setTableData(tableData: TableItem[]) {
    this.tableData = tableData
  }

  @action.bound
  addTagsToShipments(tags: string[], shipmentKeys: "All" | string[]) {
    this.tableData = this.tableData.map(v => {
      if (shipmentKeys !== "All" && !shipmentKeys.includes(v.key)) {
        return v
      }

      v.orderTags.unshift(...tags)
      v.orderTags = uniq(v.orderTags)

      return v
    })
    if (this.orderInfoData) {
      const orderTags = this.orderInfoData.orderTags || []

      orderTags.unshift(...tags)
      this.orderInfoData.orderTags = uniq(orderTags)
    }
  }

  @action.bound
  removeTagsToShipments(tags: string[], shipmentKeys: "All" | string[]) {
    this.tableData = this.tableData.map(v => {
      if (shipmentKeys !== "All" && !shipmentKeys.includes(v.key)) {
        return v
      }

      v.orderTags = v.orderTags.filter(tag => !tags.includes(tag))

      return v
    })
    if (this.orderInfoData) {
      const orderTags = this.orderInfoData.orderTags || []

      this.orderInfoData.orderTags = orderTags.filter(tag => !tags.includes(tag))
    }
  }

  @action.bound
  setItemsCount(itemsCount: number) {
    this.itemsCount = itemsCount
  }

  @action.bound
  setQueryParams(queryParams: QueryParamsType) {
    this.queryParams = queryParams
  }

  @action.bound
  setOrderInfoShow(show: boolean): void {
    this.orderInfoShow = show
  }

  @action.bound
  setOrderInfoItem(item: TableItem): void {
    this.orderInfoItem = item
  }

  @action.bound
  setOrderInfoData(orderInfoData: ITrackingResultStateData | undefined) {
    this.orderInfoData = orderInfoData
  }

  @action.bound
  setOrderInfoDataLoadedTime(time: number): void {
    this.orderInfoDataLoadedTime = time
  }

  @action.bound
  setUpdateStatusLoading(loading: boolean): void {
    this.updateOrderStatus.loading = loading
  }

  @action.bound
  setUpdateStatusData(data: UpdateStatusData): void {
    this.updateOrderStatus.data = data
  }

  @action.bound
  setUpdateStatusSingleData(key: keyof UpdateStatusData, value: UpdateStatusData[keyof UpdateStatusData]): void {
    this.updateOrderStatus.data && ((this.updateOrderStatus.data as any)[key] = value)
  }

  @action.bound
  setUpdateStatusItem(index: number, item: any): void {
    this.updateOrderStatus.data?.status_list && (this.updateOrderStatus.data.status_list[index] = item)
  }

  @action.bound
  setTableColumnSelected(item: string[]): void {
    this.tableColumnSelected = item
  }

  @action.bound
  setTableColumnChoices(choices: OptionListDragProps["choices"]): void {
    this.tableColumnChoices = choices
  }

  @action.bound
  loadMailSendRecords(tid: number): void {
    /** 格式化的邮件发送记录 */
    function formatMailSendRecords(items: MailRecordData[]) {
      const SHIPMENT_STATUS_CONVERT: { [k: string]: string } = {
        delivered: i18n.t("common:ShipmentStatus.Delivered", "Delivered"),
        exception: i18n.t("common:ShipmentStatus.Exception", "Exception"),
        failed_attempt: i18n.t("common:ShipmentStatus.FailedAttempt", "Failed attempt"),
        in_transit: i18n.t("common:ShipmentStatus.InTransit", "In transit"),
        info_received: i18n.t("common:ShipmentStatus.InfoReceived", "Info received"),
        pickup: i18n.t("common:ShipmentStatus.OutForDelivery", "Out for delivery"),
      }

      return items.map(v => ({
        ...v,
        created_at: dayjs(v.created_at)
          .tz()
          .format("MMMM DD, YYYY"),
        msg: `${i18n.t("orders:OrdersTableMsg_2", {
          type: SHIPMENT_STATUS_CONVERT[v.tpl_sort],
          toEmail: v.to_email,
        })}`,
      }))
    }

    this.mailSendRecordsLoading = true
    this.mailRecordsModalOpen = true

    OrdersAPI.mailRecords({ tid })
      .then(({ data: { data } }) => {
        this.mailSendRecords = formatMailSendRecords(data)
      })
      // .finally(() => setMailSendRecordsLoading(false))
      .finally(() => this.mailSendRecordsLoading = false)
  }
}
