import "./Asset/css/global.scss"

import {
  Badge,
  Layout,
  Page,
  Text,
  Tooltip } from "@shopify/polaris"
import dayjs from "dayjs"
import { Observer } from "mobx-react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { Container, HotBadge } from "@/component"
import DatePicker from "@/component/DatePicker"
import Tabs from "@/component/Tabs/index"
import { DateOption } from "@/pages/Orders/components"
import stores from "@/stores"

export default function DeliveryGuarantee() {
  const { t } = useTranslation(["delivery_guarantee", "orders"])
  const navigate = useNavigate()
  const { pathname } = useLocation()


  const tabList = useMemo(() => {
    return [
      {
        content: t("delivery_guarantee:General"),
        id: "/protection/general",
        keyword: "general",
      },
      {
        content: t("delivery_guarantee:Covers"),
        id: "/protection/covers",
        keyword: "covers",
      },
      {
        content: t("delivery_guarantee:Claims"),
        id: "/protection/claims",
        keyword: "claims",
      },
      {
        content: t("delivery_guarantee:Bills"),
        id: "/protection/bills",
        keyword: "bills",
      },
    ]
  }, [t])



  const handleSelectChange = (selectedValue: string, startDate: string | Date | undefined, endDate: string | Date | undefined) => {

    if (tabIndex === 0 && stores.DeliveryGuarantee.infoData.isFirstApply === 1) {
      // @ts-ignore
      stores.DeliveryGuarantee.getOverviewData(String(selectedValue), dayjs(startDate)
        .format("YYYY-MM-DD"), dayjs(endDate)
        .format("YYYY-MM-DD"))
    }
  }







  const tabIndex = useMemo(() => {
    const targetPageIndex = tabList.findIndex(tab => pathname.includes(tab.keyword)) 

    return targetPageIndex < 0 ? 0 : targetPageIndex
  }, [pathname, tabList])

  return (
    <Container maxWidth={"1036px"} className={"mt-0"} fullWidth={false}>
      <Page
        // @ts-ignore
        maxWidth={"1096px"}
        title={<div className="space-x-2 flex items-center">
          <div>
            {
              t("delivery_guarantee:Sett.DeliveryGuarantee")
            }

          </div>
          <div>
            <Badge tone="info" size="medium">
            New
            </Badge>
          </div>
        </div> as any}
        primaryAction={
          <div className={"leading-7"}>
            <Tooltip hasUnderline content={
              <div className="reset-tooltip-max-width">
                <Text as="p">
                  {t("delivery_guarantee:ParcelpanelHasYouCovered")}<br />{t("delivery_guarantee:TheInsuranceDepartmentWarnsConsumers")}
                </Text>
      
              </div>
            } 
            >
              <Text as={"span"}>   {t("delivery_guarantee:Sett.PoweredBySeel")}</Text>
            </Tooltip>
         
          </div>
        }
      >
        <Layout>
          <Layout.Section>
            <div className="block justify-between sm:flex">
              <div className="flex-1">
                <Tabs
                  tabs={tabList}
                  activeIndex={tabIndex}
                  onChange={(index: number) => {
                    navigate(tabList[index].id || "/protection")
                    // setDataTimeSelected(DateOption.PastQuarter)
                  }}
                />
              </div>
              <Observer>
                {() => <div className={"text-right mt-2 md:mt-0"}>
                  {tabIndex === 0 && stores.DeliveryGuarantee.infoData.isFirstApply === 1 && <DatePicker
                    preferredAlignment={"right"}
                    buttonProps={{ variant: "secondary" }}
                    onApply={(selected: number, startDate: Date, endDate: Date) => {
                
                      const value = [[0, DateOption.Custom], [1, DateOption.Today], [7, DateOption.PastWeek], [30, DateOption.PastMonth],
                        [60, DateOption.PastTwoMonths], [90, DateOption.PastQuarter], [120, DateOption.PastFourMonths]].find(v => {
                        return v[0] === selected
                      })
 
                      if (value){
                        handleSelectChange(String(value[1]), startDate, endDate)
                      }
                      
                    }}
                  />
                  }
                </div>}
              </Observer>
            </div>
          </Layout.Section>
          <Layout.Section>
            <Outlet />
          </Layout.Section>
        </Layout>
      </Page>

    </Container>
  )
}
