import { Button, ButtonGroup, LegacyFilters, Pagination, ResourceItem, ResourceList } from "@shopify/polaris"
import { ArrowDownIcon, ArrowUpIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import { debounce, slice } from "lodash-es"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import LazyLoadImage from "@/component/LazyLoadImage"

import styles from "./index.module.scss"

export enum ArrowSortType {
  Up,
  Down,
}


export interface CourierMatchingTableProps<ItemType = any> {
  emptyState?: React.ReactNode;
  items: ItemType[];
  selectedCouriers?: ItemType[];
  perPage?: number;
  arrowSortable?: boolean;
  enableButton?: boolean;
  queryValue: string;

  onEnable(ids: string[]): void;

  onDisable(ids: string[]): void;

  onArrowSort?(ids: string[], mode: ArrowSortType): void;

  onSelectionChange(items: ItemType[]): void;

  /** Function to customize the unique ID for each item */
  idForItem?(item: ItemType, index: number): string;

  onQueryChange(queryValue: string): void;
}


const expressLogoUrl = "//cdn.parcelpanel.com/assets/common/images/express/"


export function CourierMatchingTable<ItemType>({
  items,
  selectedCouriers = [],
  perPage,
  arrowSortable,
  enableButton,
  onEnable,
  onDisable,
  onArrowSort,
  onSelectionChange,
  idForItem = defaultIdForItem,
  emptyState,
  queryValue,
  onQueryChange,
}: CourierMatchingTableProps) {
  const { t } = useTranslation(["common", "settings"])
  const [page, setPage] = useState<number>(1)
  const [isQueried, setIsQueried] = useState<boolean>(false)
  const [queryResult, setQueryResult] = useState<ItemType[]>([])

  const totalItemsCount = (isQueried ? queryResult : items).length

  let renderItems: ItemType[] = []
  let hasPrevious
    , hasNext

  if (perPage) {
    const start = (page - 1) * perPage
    const end = start + perPage

    renderItems = slice(isQueried ? queryResult : items, start, end)

    hasPrevious = 0 < start
    hasNext = end < totalItemsCount
  } else {
    renderItems = isQueried ? queryResult : items
  }


  /** 监听 queryValue 从外部更改 */
  useEffect(() => {
    setPage(1)
    debounceQuery(items, queryValue)
  }, [queryValue])

  /** items 变更了就重新刷新一下筛选结果 */
  useEffect(() => {
    isQueried && setQueryResult(selectMatchItem<ItemType>(items, queryValue))
  }, [items])


  const debounceQuery = useMemo(() => debounce((items, queryValue) => {
    // 清空所有选中项
    onSelectionChange([])
    setQueryResult(selectMatchItem<ItemType>(items, queryValue))
    setIsQueried(0 < queryValue.length)
  }, 200), [])

  const handleQueryChange = (queryValue: string) => {
    setPage(1)
    onQueryChange(queryValue)
    debounceQuery(items, queryValue)
  }

  const handleQueryClear = () => {
    setPage(1)
    onQueryChange("")
    setQueryResult([])
    setIsQueried(false)
  }

  const handleArrowSort = (id: string, mode: ArrowSortType) => {
    const index = renderItems.findIndex((item, index) => id === idForItem(item, index))
    const targetIndex = index + (mode === ArrowSortType.Up ? -1 : 1)

    onArrowSort && onArrowSort([id, idForItem(renderItems[targetIndex], targetIndex)], mode)
  }

  const handlePaginate = (action: number) => {
    setPage(page + action)
    onSelectionChange([])
  }

  const promotedBulkActions = []

  if (arrowSortable) {
    promotedBulkActions.push({
      content: t("common:Disable"),
      onAction: () => onDisable(selectedCouriers),
    })
  }

  if (enableButton) {
    promotedBulkActions.push({
      content: t("common:Enable"),
      onAction: () => onEnable(selectedCouriers),
    })
  }

  const handleResourceItemClick = (v?: string) => {
    v && onSelectionChange([v])
  }

  const filterControl = (
    <div className={"p-1"}>
      <LegacyFilters
        queryPlaceholder={t("settings:CourierMatching.SearchCouriers")}
        disabled={!items.length}
        filters={[]}
        queryValue={queryValue}
        onClearAll={handleQueryClear}
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryClear}
      />
    </div>
  )


  return (
    <>
      <ResourceList<any>
        resourceName={{ plural: t("settings:couriers"), singular: t("settings:courier") }}
        selectable
        totalItemsCount={(hasPrevious || hasNext) ? totalItemsCount : 0}
        items={renderItems}
        promotedBulkActions={promotedBulkActions}
        selectedItems={selectedCouriers}
        onSelectionChange={onSelectionChange}
        filterControl={filterControl}
        idForItem={idForItem}
        emptyState={emptyState}
        renderItem={(item, id, index) => {
          // todo 类型处理
          // @ts-ignore
          const { name, img } = item

          const isFirstItem = index === 0
          const isLastItem = index === totalItemsCount - 1

          return (
            <ResourceItem
              persistActions
              id={`${id}`}
              media={
                <span className={styles.expressLogoWrapper}>
                  <LazyLoadImage key={`lazy-load-image-${id}`} src={`${expressLogoUrl}${img}`} alt={`${name} LOGO`} />
                </span>
                // <Thumbnail size="48" source={`${expressLogoUrl}${img}`} alt={`${name} logo`} />
              }
              onClick={handleResourceItemClick}
            >
              <div className={classNames("flex", "items-center", styles.CourierHeight)}>
                <div className="flex-1">{name}</div>

                <div
                  onClick={stopPropagation}
                >
                  {
                    arrowSortable && !isQueried && (
                      <ButtonGroup variant="segmented">
                        <Button
                          icon={ArrowDownIcon}
                          disabled={isLastItem}
                          onClick={() => handleArrowSort(id, ArrowSortType.Down)}
                        />
                        <Button
                          icon={ArrowUpIcon}
                          disabled={isFirstItem}
                          onClick={() => handleArrowSort(id, ArrowSortType.Up)}
                        />
                      </ButtonGroup>
                    )
                  }

                  {
                    enableButton && (
                      <Button
                        size={"large"}
                        onClick={() => onEnable([id])}
                      >
                        {t("common:Enable")}
                      </Button>
                    )
                  }
                </div>
              </div>
            </ResourceItem>
          )
        }}
      />

      {
        /* 分页 */
        (hasPrevious || hasNext)
          && (
            <div className="mt-4 pb-4 flex justify-center">
              <Pagination
                hasPrevious={hasPrevious}
                hasNext={hasNext}
                onNext={() => handlePaginate(1)}
                onPrevious={() => handlePaginate(-1)}
              />
            </div>
          )
      }
    </>
  )
}


/** 模糊搜索对象 */
function selectMatchItem<IItemType>(items: IItemType[], keyword: string) {
  const reg = new RegExp(keyword, "gi")

  // @ts-ignore
  return items.filter(item => Object.values(item)
    .some(v => reg.test(v as string)))
}

function stopPropagation(event: React.MouseEvent<any>) {
  event.stopPropagation()
}

function defaultIdForItem<ItemType extends { id?: any }>(
  item: ItemType,
  index: number,
) {
  return Object.prototype.hasOwnProperty.call(item, "id")
    ? item.id
    : index.toString()
}
