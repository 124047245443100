import { cloneDeep, isEmpty, set } from "lodash-es"
import { action, makeObservable, observable } from "mobx"

import settingsApi from "@/api/settings"
import stores from "@/stores"
import { deepDiffBetweenObjects } from "@/utils/ObjectUtils"

export default class GeneralStore {
  @observable loading = false
  @observable config:{
    date_format: number;
    time_format: number;
    quick_setting_url: string;
  } = {
      quick_setting_url: "",
      date_format: 0,
      time_format: 0,
    }

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setConfig(id: "date_format" | "time_format", value: number){
    this.config[id] = value
    stores.commonStore.setContextualSaveBar("show", !isEmpty(this.getDiffOptions()))
  }

  @action.bound
  getGeneralData() {
    this.loading = true
    settingsApi.getGeneralConfig()
      .then(( { data: { general_settings } }) => {
        const { update_tracking_url_in_shopify, push_fulfillment_shipment_status, date_format, time_format } = general_settings

        stores.userInfoSore.setButtonSwitchAll("replaceOrderLink", update_tracking_url_in_shopify)
        stores.userInfoSore.setButtonSwitchAll("onlineStoreDashboard", push_fulfillment_shipment_status.enabled)
        stores.userInfoSore.setButtonSwitchAll("triggerNativeNotification", push_fulfillment_shipment_status.notify_customer)

        this.config = {
          quick_setting_url: push_fulfillment_shipment_status.quick_setting_url,
          date_format,
          time_format,
        }

        settingsApi.setGeneralInitOptions({
          date_format,
          time_format,
        })
      })
      .finally(() => {
        // setConfigLoading(false)
        this.loading = false
      })
  }

  @action.bound
  revertConfig(){
    this.config = { ...this.config, ...settingsApi.getGeneralInitOptions() }
  }

  @action.bound
  getDiffOptions(): boolean {
    return deepDiffBetweenObjects({
      date_format: this.config.date_format,
      time_format: this.config.time_format,
    }, settingsApi.getGeneralInitOptions())
  }
}
