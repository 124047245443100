import { Button, SkeletonBodyText } from "@shopify/polaris"
import { get } from "lodash-es"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { WithTranslation, withTranslation } from "react-i18next"

import { InfoCard } from "@/component"
import CopyFiled from "@/component/CopyFiled/CopyFiled"
import stores from "@/stores"
import { navigateToCustomerById } from "@/utils/redirectShopify"

type IProps = WithTranslation<"orders" | "common">

@observer
class CustomerInfo extends Component<IProps, any> {
  public render() {
    const { orderInfoData } = stores.ordersStore
    const dataLoading = stores.ordersStore.orderInfoDataLoadedTime === 0
    const { t } = this.props

    const normalList = (
      <InfoCard.NormalList data={[
        {
          filed: t("CustomerName"),
          value: <Button
            onClick={() => navigateToCustomerById(String(get(orderInfoData, "customer_id")))}

            variant="plain"
          >{orderInfoData?.customer_name}</Button> || "-",
        },
        {
          filed: t("Email", { ns: "common" }),
          value: <CopyFiled copyValue={orderInfoData?.email} toolTipText={t("CopyEmail")}>
            {orderInfoData?.email}
          </CopyFiled>,
        },
        {
          filed: t("PhoneNumber"),
          value: <CopyFiled copyValue={orderInfoData?.phone_number} toolTipText={t("CopyPhoneNumber")}>
            {orderInfoData?.phone_number}
          </CopyFiled>,
        },
        {
          filed: t("ShippingAddress"),
          value: orderInfoData?.shipping_address || "-",
        },
      ]}
      />
    )

    return (
      <InfoCard title={t("CustomerInfo")}>
        {dataLoading ? <SkeletonBodyText lines={4} /> : normalList}
      </InfoCard>
    )
  }
}

export default withTranslation("orders")(CustomerInfo)
