import { action, computed, makeObservable, observable } from "mobx"

import accountApi from "@/api/account"
import { AppPricingInterval, CurrentSubscription, PayType, Plan, QuotaReminder } from "@/api/account/type.d"
import { PermissionRules } from "@/config/permission-rules"
import IUpgradeStore from "@/models/IUpgradeStore"


export default class UpgradeStore implements IUpgradeStore {
  @observable upgradeModalActive: boolean = false
  @observable payType: PayType = AppPricingInterval.Every30_Days // 年费 or 月费
  @observable btnText: string = "Upgrade now" // 在首次获取用户信息时，userInfo.ts --> setData() 方法就设置了这个文本
  @observable initialized: boolean = false
  @observable currentPlanInitialized: boolean = false
  @observable plans: Plan[] = []
  @observable currentPlan: CurrentSubscription = {
    "version": 2,
    "additional_quota_price": "0.05",
    "is_sub_shop": false,
    "quota_reminder": null,
    "spending_limit": "",
    "level": 0,
    "pricing_id": 0,
    "plan_name": "",
    "quota_total": 0,
    "quota_remain": 0,
    "additional_quota_used": 0,
    "interval": AppPricingInterval.Annual,
    "expiration_date": "",
    "billing_cycle_end_date": "",
    "trial_end_date": "",
    "next_plan": {
      "pricing_id": 0,
      "name": "",
      "quota": 0,
      "level": 0,
      "version": 4,
    },
    pricing_update: null,
  }
  @observable minPlanLevel: number = 2
  @observable activePlanLevel: number = 2
  @observable activeIndex: number[] = []

  constructor() {
    makeObservable(this)
  }

  @computed get prevPlan() {
    const prev = this.plans.find(item => item.level === (this.activePlanLevel - 1))

    return prev as Plan
  }

  @computed get targetPlan() {
    const target = this.plans.find(item => item.level === this.activePlanLevel)

    return target as Plan
  }

  @action.bound
  handleCloseModal(): void {
    this.upgradeModalActive = false
  }

  @action.bound
  changeSpendingLimit(spending_limit:string): void {
    this.currentPlan.spending_limit = spending_limit
  }

  @action.bound
  showModal(permissionRule: PermissionRules): void {
    this.activeIndex = permissionRule.active_index
    this.showUpgrade(permissionRule.plan_member || 1)
  }

  /**
   * 清除套餐更新数据
   */
  @action.bound
  clearPricingUpdate(): void {
    this.currentPlan.pricing_update = null
  }

  /**
   * 展示最新付费引导
   * @param level
   */
  @action.bound
  showUpgrade(level: number): void {
    this.minPlanLevel = level // 最低level
    this.activePlanLevel = level
    this.upgradeModalActive = true
  }

  @action.bound
  setBtnText(btnText: string): void {
    this.btnText = btnText
  }

  @action.bound
  setPayType(type: PayType): void {
    this.payType = type
  }

  @action.bound
  setPlans(data: Plan[]): void {
    this.plans = data
    this.initialized = true
  }

  @action.bound
  setCurrentPlan(data: CurrentSubscription, current_discount_interval?: AppPricingInterval): void {
    this.currentPlan = data
    if (data.interval === AppPricingInterval.Every30_Days) {
      // 如果是免费套餐，且当前折扣码是年费优惠. 老套餐也适用
      if ((data.level === 1 || data.level === 0) && current_discount_interval === AppPricingInterval.Annual) {
        this.setPayType(AppPricingInterval.Annual)
      } else {
        this.setPayType(AppPricingInterval.Every30_Days)
      }
    } else if (data.interval === AppPricingInterval.Annual) {
      this.setPayType(AppPricingInterval.Annual)
    }
    this.currentPlanInitialized = true
  }

  @action.bound
  setMinPlanLevel(level: number): void {
    this.minPlanLevel = level
  }

  @action.bound
  setActivePlanLevel(level: number): void {
    if (this.activePlanLevel !== level) {
      this.activePlanLevel = level
    }
  }

  @action.bound
  updatePlans() {
    accountApi.getPlanList()
      .then(({ data: { plans } }) => {
        this.initialized = true
        this.setPlans(plans)
      })
  }
  @action.bound
  updateQuotaReminder(quota_reminder: QuotaReminder) {
    this.currentPlan.quota_reminder = quota_reminder
  }
}
