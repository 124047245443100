import { Button } from "@shopify/polaris"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { DashboardSetupGuideEnum } from "@/enums/dashboard"
import StepWrapper from "@/pages/Dashboard/components/QuickSetupGuide/StepWrapper"

type IProps = {
  active: boolean
  isDone?: boolean
  step: DashboardSetupGuideEnum
  toggleCompleted: (status: DashboardSetupGuideEnum, completed: boolean) => Promise<any>
  titleClick?: () => void
}

const CustomizeShippingNotifications = (props: IProps) => {
  const { t } = useTranslation(["dashboard", "common"])
  const navigate = useNavigate()
  const { toggleCompleted, step, isDone } = props

  const handleDoneStep = () => {
    if (typeof toggleCompleted === "function" && !isDone) {
      toggleCompleted(step, true)
    }
  }

  return (
    <StepWrapper
      {...props}
      title={t("dashboard:CustomizeShippingNotifications")}
      type={"svg"}
      mediaNode={<img src="https://cdn.parcelpanel.com/images/guide1.svg" />}
    >
      {t("dashboard:CustomizeShippingNotificationsDesc")}

      <div className={"mt-3 flex items-center"}>
        <Button
          variant={!props.isDone ? "primary" : undefined}
          external
          onClick={() => {
            handleDoneStep()
            navigate("/settings/shipping-notifications", { state: { dropShippingModeTip: true } })
          }}
        >
          {t("dashboard:DashboardEdgeTable.Notifications.button")}
        </Button>
      </div>
    </StepWrapper>
  )
}

export default CustomizeShippingNotifications
