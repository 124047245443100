import { CheckboxProps } from "@shopify/polaris/build/ts/latest/src/components/Checkbox/Checkbox"
import React from "react"

import { QueryParamsType } from "@/pages/Orders/types"

export enum DayTimeEnum {AM = "am", PM = "pm"}

export enum UpdateStatusSelectFrom {
  SelectAll = "SelectAll", // 选择全部：从 Orders 选择大于 10+ 会是这个值
  MultiSelect = "MultiSelect", // 多选：从 Orders 选择小于等于 10 会是这个值
  ShopifySelect = "ShopifySelect"// 从 Shopify Orders 页面选择
}

export interface ISearchParams {
  from: UpdateStatusSelectFrom,
}

export interface IQueryParams {
  order_ids?: string[]; // 从 Shopify Orders 进来的 || 选择所有订单，携带这个参数
  number_ids?: string[]; // number 表 ID，只有当选择 SelectAll、MultiSelect 才会有
  id?: string;// 从 Shopify Orders 进来的 <选择单个订单>
}

export interface IUpdateStatusState {
  // 只有当从 orders -> 选择大于 10+ 才会有这个参数
  queryParams?: QueryParamsType,
}

export interface UpdateStatusStatusListProps {
  // autoStatusUpdate: boolean;// 是否开启<状态自动更新>
  // updateStatusData?: UpdateStatusData;
}

export type UpdateStatusItemOnClickType = {
  currentIndex: UpdateStatusItemProps["index"], // 当前 item 索引
  currentSelected: UpdateStatusItemProps["isSelected"], // 是否展开了 日期选择器
  currentChecked: UpdateStatusItemProps["checked"], // 是否选中了
};

export interface UpdateStatusItemProps extends Pick<Required<CheckboxProps>, "id" | "checked" | "disabled" | "onChange"> {
  title: React.ReactNode;
  subTitle?: React.ReactNode;

  isSelected: boolean // 选中了？
  loading: boolean; // 加载中？

  disabled: boolean;

  timestamp: number;// 时间戳
  index: number;// 存数组下标

  children?: React.ReactNode;

  onClick({ currentIndex, currentSelected, currentChecked }: UpdateStatusItemOnClickType): void;

  /**
   * 日期改变事件
   * @param index
   * @param t 时间戳
   */
  onDatetimeChange(index: number, t: number): void;
}

// ////////////////////////////////

export interface UpdateStatusData {
  custom_track_status: number;// 勾的最后一个状态
  status_list: StatusList[];
  custom_status?: CustomStatus[];
  status_checked: number;
  order_has: number;// 1：不存在  2：存在
  auto: boolean;// true：开启自动更新  false：关闭自动更新
}

export interface StatusList {
  name: string;
  key: string;
  status: number;

  time?: string;// 时间 day，只有自定义状态的才会有
  info?: string;
  date?: string;
  timestamp?: number;
  value?: boolean;// 是否选中
}

export interface CustomStatus {
  status: string;
  info: string;
  time: string;
}
