import { Text } from "@shopify/polaris"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import Api from "@/pages/DeliveryGuarantee/Api"
import { InsurancePointType } from "@/pages/DeliveryGuarantee/Api/type"
import stores from "@/stores"

interface ToProtectionProps {
  pointType?: InsurancePointType
  needCheckShipment?:boolean
}
const ToProtection = (props: ToProtectionProps) => {
  const { pointType, needCheckShipment = false } = props
  const { userInfoSore } = stores
  const { is_pi_eligible, is_pi_active, has_exception_shipment } = userInfoSore.data
  const isPass = is_pi_eligible && !is_pi_active
  const isShow =  needCheckShipment ? (isPass && has_exception_shipment) : isPass
  const navigate = useNavigate()
  const { t } = useTranslation("common")

  if (!isShow) return null
  return (
    <div className="ml-2 inline-block group cursor-pointer" onClick={(e) => {
      e.stopPropagation()
      if (pointType){
        Api.postPoint({
          point_type: pointType,
        })
      }
     
      navigate({
        pathname: "/protection",
      })
    }}
    >
      <div>
        {
          <div className="flex items-center group-hover:underline underline-offset-2 pl-2  pr-1 rounded-lg  hover:bg-[#EAF4FF] text-[#00527C]" style={{
            border: "1px solid var(--p-color-border-info)",
          }}
          >
            <div className="text-xs py-[2px]">
              <Text fontWeight="medium" as="p">{t("GetProtection")}</Text>
            </div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3.49957 10C3.49957 9.58579 3.83536 9.25 4.24957 9.25L13.9392 9.25L11.2193 6.53036C10.9264 6.23748 10.9263 5.7626 11.2192 5.4697C11.5121 5.17679 11.987 5.17676 12.2799 5.46964L16.2803 9.46964C16.4209 9.6103 16.5 9.80107 16.5 10C16.5 10.1989 16.4209 10.3897 16.2803 10.5304L12.2799 14.5304C11.987 14.8232 11.5121 14.8232 11.2192 14.5303C10.9263 14.2374 10.9264 13.7625 11.2193 13.4696L13.9392 10.75L4.24957 10.75C3.83536 10.75 3.49957 10.4142 3.49957 10Z" fill="#00527C" />
            </svg>
          </div> as any
        }
      </div>
    </div>
  )
}

export default observer(ToProtection)
