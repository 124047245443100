
export interface BaseResponse<T> {
  code: number
  msg: string
  data: T,
}

export interface OverviewApi {
  orders: string;
  revenue: number;
  sales: number
  symbol: string
  isOrder: boolean
}

export interface ScreenTime {
  claimAt?: string
  dateFrom?: string
  dateTo?: string
}


export interface InfoApi {
  user_id: string;
  // 绑卡 卡的尾号
  payNumber: string;
  // 审核状态 0未申请 1申请
  audit: number,
  // 失败原因 当为审核不通过可展示
  auditRet: string,
  // 货币
  currency: string,
  // 货币符号
  symbol: string,
  // 是否是付费用户  true为付费用户
  isPaid: boolean,
  // 是否查看最终审核结果 0未查看1已查看
  isViewAudit: number,
  // 完成支付方式设置 0 未设置 1自动扣费 2手动扣费
  paymentMethod: number,
  // 店铺版本
  planDisplayName: string,
  // 店铺名称
  storeName: string
  // 是否首次申请 0 首次申请（展示初始页面） 1非首次申请按流程展示
  isFirstApply: number
  // 是否plus用户
  isPlus: boolean
  // 跳转授权链接
  authUrl: string
  // 是否授权
  isAuth: boolean
  isShowBanner: boolean
  isShowModal: boolean
  // true 展示 false隐藏
  isDisplayStatus: boolean
}


// 默认开启 2默认关闭 1默认开启
export enum DefaultStatusEnum {
  Open = 1,
  Close
}

// 运输险商品履行类型  1 立即履行 2手动发货 3有一个则履行 4 全部履行
export enum FulfillmentEnum {
  WithFirstItem = 1,
  WithOtherItem,
  UponPurchase,
  NotFulfill
}
// 0未申请适配 1未适配 2适配 3无法适配
export enum FitEnum {
  NotFitted = 1,
  Fitted,
  CannotFitter
}

export enum InstallMethodEnum {
  undefined,
  expert,
  auto
}

export enum PublishTypeEnum {
  auto = 1,
  manual
}

export enum AutoInstallStatusEnum {
  // 不展示弹窗
  hide,
  // 展示成功弹窗
  success,
  // 展示失败弹窗
  error
}


export type InsurancePointType = "banner1" | "banner2" | "exception_card" | "analytics_exception_card" | "orders_exception_card" | "notifications_to_customers" | "notifications_to_yourself_exception"
export interface CartSettingApi {
  /**
   * 1自动发布2手动发布
   */
  autoPublish: PublishTypeEnum;
  /**
   * 默认开启 2默认关闭 1默认开启
   */
  defaultDisplayStatus: DefaultStatusEnum;
  /**
   * xicon false关闭 true开启
   */
  isShowTip: boolean;
  /**
   * 适配类型 0未发起 1专家适配 2自动适配
   */
  fitType: InstallMethodEnum;
  /**
   * 运输险商品履行类型  1 立即履行 2手动发货 3有一个则履行 4 全部履行
   */
  fulfillmentType: FulfillmentEnum;
  /**
   * bool
   */
  isEnable: boolean;
  /**
   * 0未申请适配 1未适配 2适配 3无法适配
   */
  isFit: FitEnum;
  /**
   * true 已安装  I've done this button不可点击
   * false 未安装  I've done this button可点击
   */
  plusBlock: boolean;
  /**
   * 自动适配状态  0不展示弹窗 1展示成功弹窗 2展示失败弹窗
   */
  autoInstallStatus: AutoInstallStatusEnum;
  /**
* true 首次开启 flase 非首次开启
*/
  firstEnable: string;
  /**
 * true 展示呼吸效果  false隐藏呼吸效果
 */
  isBreathe: boolean;
  /**
   * 是否在trackingPage显示
   */
  isShowShippingProtection:boolean
  [property: string]: any;
}


export interface CheckWidgetApi {
  // 跳转链接
  appEmbedLink: string,
  // 是否开启
  enableWidget: boolean,
  //
  scopeUrl: boolean,
  //
  webExtension: WebExtension
}
interface WebExtension {
  insuranceCheckout: boolean
  /**
   * 是否开启小部件
   */
  insurancePopup: boolean
}

export interface ParamsApi {
  // 当前页
  page: number,
  // 页展示数量
  pageSize?: number,
  // 筛选
  status?: string[],
  // 时间
  claimAt?: string,
  // 开始时间
  dateFrom?: string,
  // 结束时间
  dateTo?: string
  // 搜索值
  query?: string
}


export type TransactionsData = {
  /**
   * 总条数
   */
  allTotal: string;
  /**
   * 支付总条数
   */
  paidTotal: string;
  /**
   * 处理中总数
   */
  processingTotal: string;
  /**
   * 未支付总数
   */
  unPaidTotal: string;
} & ListData<TransactionsListApi>


export interface ListData<T> {
  list: T[];
  pageSize: number;
  total: string;
}

export interface ContractList {
  contractId: string;
  createAt: string;
  customer: string;
  orderNumber: string;
  protectedCost: number;
  protectedValue: number;
  status: string;
  orderId: string
  symbol: string
  currency: string
  shopSumInsured: string
  shopPremium: string
}



export interface ClaimList {
  claimId: string;
  claimType: string;
  contractId: string;
  createAt: string;
  orderNumber: string;
  rejectReason: string;
  status: string;
  orderId: string;
  symbol: string;
  payoutAmount:number
  currency:string
}

export interface WidgetFormParams {
  /**
   * 默认开启：1默认开启 2默认关闭
   */
  defaultDisplayStatus: DefaultStatusEnum;
  /**
   * 发货设置：1 有一个发货则发货 2全部履行才发货 3立即履行 4 手动发货
   */
  fulfillmentType: FulfillmentEnum;
    /**
   * 是否在trackingPage显示
   */
  isShowShippingProtection:boolean
}


export interface TransactionsListApi {
  amountDue: number
  billId: string
  createdAt: string
  credit: number
  cycleEndTime: string
  cycleStartTime: string
  fee: number
  invoiceUrl: string
  paidTime: string
  status: string
  symbol: string
  currency: string
  currencySymbol: string
  cycleEndTimeHour: string
  cycleStartTimeHour: string
}


export interface SysConfigA {
  // plus的是否已经设置完成配置
  plusBlock: number
  // 小部件完成适配banner
  remindOne: number
  // 商店修改的新货币不受支持而被停用banner
  remindTwo: number
  // 店铺当前不属于PP 的付费用户而被停用banner
  remindThr: number
  // 店铺有欠款未缴而被停用banner
  remindFour: number
  // 店铺赔付太差而被停用banner
  remindFive: number
  // bills页面 in-cart banner
  billTip: boolean
}


export interface AutoSearchInterface {
  coverStatusSelected?:string[]
  currentPage?:number
  query?:string
  dataTime?:string
  dateTimeStartingSelected?:any
  dateTimeEndingSelected?:any
}
