import { action, makeObservable, observable } from "mobx"

import { APICode } from "@/api/APICode"
import { IContextualSaveBar } from "@/models/ICommonStore"
import Api from "@/pages/DeliveryGuarantee/Api"
import { AutoInstallStatusEnum, CartSettingApi, CheckWidgetApi, InfoApi, OverviewApi, SysConfigA } from "@/pages/DeliveryGuarantee/Api/type"
import { auditRetEnum } from "@/pages/DeliveryGuarantee/common"


export default class deliveryGuarantee {
  // 按钮loading
  @observable isApplyBtLoading = false

  // 请求审核的弹窗
  @observable guaranteeModule = false

  // 高亮弹窗配置
  @observable highlightShow = false

  // 欠款未缴账单
  @observable public unpaidBillNum = 0

  // 走完绑卡跟授权切换配置
  @observable public countdownTimer = true

  @observable sysConfig: SysConfigA = {
    plusBlock: 0,
    remindOne: 0,
    remindTwo: 0,
    remindThr: 0,
    remindFour: 0,
    remindFive: 0,
    billTip: true,
  }

  @observable overviewData: OverviewApi = {
    orders: "",
    revenue: 0,
    sales: 0,
    symbol: "",
    isOrder: false,
  }

  @observable dateTime = {
    selectTime: "",
    startTime: "",
    endTime: "",
  }

  @observable infoData: InfoApi = {
    user_id: "",
    payNumber: "",
    audit: 0,
    auditRet: "",
    currency: "CNY",
    symbol: "",
    isPaid: false,
    isViewAudit: 0,
    paymentMethod: 0,
    storeName: "",
    isFirstApply: 0,
    planDisplayName: "",
    isPlus: false,
    isAuth: true,
    authUrl: "",
    isShowModal: false,
    isShowBanner: false,
    isDisplayStatus: false,
  }

  @observable cartSetting: CartSettingApi = {
    defaultDisplayStatus: 2,
    fulfillmentType: 2,
    isEnable: false,
    isFit: 2,
    autoPublish: 1,
    isShowTip: false,
    fitType: 0,
    plusBlock: false,
    isBreathe: true,
    isShowShippingProtection: false,
    firstEnable: "",
    autoInstallStatus: AutoInstallStatusEnum.hide,
  }

  @observable public widgetSaveBar: IContextualSaveBar = {
    disabled: false,
    loading: false,
    show: false,
    unlocking: false,
  }

  @observable public detectionCheck: CheckWidgetApi = {
    appEmbedLink: "",
    // 是否开启
    enableWidget: false,
    scopeUrl: false,
    webExtension: {
      insuranceCheckout: false,
      insurancePopup: true,
    },
  }


  // @observable trackingLinHistoryData:TrackingLinkData | undefined;


  constructor() {
    makeObservable(this)
  }


  @action.bound
  setHighlightShow(v: boolean) {
    this.highlightShow = v
  }

  @action.bound
  setGuaranteeModule(v: boolean) {
    this.guaranteeModule = v
  }


  @action.bound
  async detectionCheckWidget(skipCheck = false) {
    const isQuery = (this.infoData.isFirstApply === 1 && this.infoData.isAuth) || skipCheck

    if (isQuery) {
      const res = await Api.detectionCheckWidget()

      if (res.data.code === APICode.OK) {
        this.detectionCheck = res.data?.data
      }

    }
  }

  @action.bound
  async applicationAuditAndShowModalOrNot(): Promise<boolean> {

    const resAudit = await Api.auditReview()

    if (resAudit.data.code === APICode.OK) {
      await this.getInfo()
      return resAudit.data.data.auditRet === auditRetEnum.success
    }
    return false
  }

  @action.bound
  setCartSetting(value:CartSettingApi) {
    // value.plusBlock = false
    this.cartSetting = value
  }

  @action.bound
  setWidgetSaveBar(key: keyof IContextualSaveBar, btnText: any): void {
    this.widgetSaveBar[key] = btnText as never
  }

  @action.bound
  setSysConfig(value:SysConfigA): void {
    this.sysConfig = value
  }

  @action.bound
  async setSysConfigByKey(key:keyof SysConfigA, val:any, syncToServer = true){
    if (syncToServer){
      const res =  await Api.setSysConfig({
        [key]: val,
      })

      if (res.data.code !== 200) return
    }
    this.setSysConfig({
      ...this.sysConfig,
      [key]: val,
    })
  }


  @action.bound
  async getSysConfig() {
    const o = await Api.getSysConfig()

    if (o.data.code === 200) {
      this.setSysConfig(o.data.data)
    }
  }

  @action.bound
  async  getCartSetting() {
    const o =  await  Api.getCartSetting()

    if (o.data.code === 200) {
      const { data } = o.data

      // data.isEnable = true
      // data.plusBlock = false
      // data.isShowShippingProtection = false
      
      this.setCartSetting(data)
    }
  }

  @action.bound
  // 获取用户数据
  async getInfo() {
    const res = await Api.getInfo()

    if (res.data.code === APICode.OK) {
 
      const { data } = res.data

      // data.isFirstApply = 1
      // data.isPaid = true
      // data.isPlus = true
      this.infoData = data
    }

  }

  @action.bound
  // 获取用户数据
  async getOverviewData(dataTime: string, startTime?:string, endTime?:string) {

    this.dateTime.selectTime = dataTime
    // @ts-ignore
    this.dateTime.startTime = startTime
    // @ts-ignore
    this.dateTime.endTime = endTime

    const data = dataTime === "Custom" ? {
      dateFrom: startTime,
      dateTo: endTime,
    } : {
      claimAt: dataTime.toUpperCase()
        .replace(/\s+/g, "_"),
    }

    const res = await Api.getOverviewData(data)

    if (res.data.code === APICode.OK) {
      this.overviewData = res.data.data
    }

  }

  @action.bound
  getUnpaidBillNum(){
    Api.getTransactUnpaidCount().then(res => {
      if (res.data.code === APICode.OK) {
        this.unpaidBillNum = res.data.data.total ? Number(res.data.data.total) : 0
      }
    })
  }

}
