import dayjs from "dayjs"
import { set } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"

import {
  ContentType,
  moduleType,
  orderStatusType,
  TemplateConfigType,
  variablesType,
} from "@/pages/Settings/EmailEdit/type"
import { onloadEmailTemplate } from "@/pages/Settings/EmailEdit/utils/editListener"

import { defaultConfig, defaultVariables } from "./defaultConfig"

class EmailEditStore {
  @observable public loading = false
  @observable public saveLoading = false
  @observable dataLoadedTime: number = 0
  @observable public isHtmlEdit: boolean = false
  @observable public showSubModal: boolean = false
  @observable public selectCollection: { label: string, value: string }[] = []
  @observable public orderStatus: orderStatusType = orderStatusType.InfoReceived
  @observable public locale: string | undefined = defaultConfig.locale // 当前的默认语言
  @observable public activeModule: moduleType | undefined
  @observable public saveBtnActive: boolean = false
  @observable public variables: variablesType = defaultVariables
  @observable public config: TemplateConfigType = defaultConfig
  @observable public auto_multilingual: boolean = defaultConfig.branding_config.auto_multilingual
  @observable public notification_enabled: Record<orderStatusType, boolean> = defaultConfig.notification_enabled
  @observable public sender: { email: string, is_valid: boolean } = defaultConfig.sender

  constructor() {
    makeObservable(this)
  }

  /**
   * 获取变量值
   */
  @computed get vars(): any {
    const tempVars: Record<string, any> = {}

    for (const key in this.variables.vars) {
      this.variables.vars[key].forEach(item => {
        if (item.value !== null) {
          tempVars[item.key] = item.value
        } else {
          tempVars[item.key] = `{{${item.key}}}`
        }
      })
    }
    return tempVars
  }

  /**
   * 获取变量值
   */
  @computed get subjectValue(): any {
    let { subject } = this.getContentConfig()

    for (const key in this.vars) {
      subject = subject.replaceAll(`{{${key}}}`, this.vars[key])
    }
    return subject
  }

  /**
   * 设置配置
   */
  @action.bound
  public setConfig(config: TemplateConfigType): void {
    this.dataLoadedTime = dayjs()
      .unix()

    delete config.locale // 剔除locale属性
    delete config.branding_config.auto_multilingual // 剔除auto_multilingual属性
    delete config.sender // 剔除auto_multilingual属性
    this.config = config
  }

  /**
   * 切换当前语言
   */
  @action.bound
  public setAutoMultilingual(status: boolean): void {
    this.auto_multilingual = status
  }

  /**
   * 切换当前语言
   */
  @action.bound
  public setLocale(locale?: string, toastText?: string): void {
    this.locale = locale
    toastText && onloadEmailTemplate(locale, toastText)
  }

  /**
   * 变更验证
   */
  @action.bound
  public setSender(sender: { email: string; is_valid: boolean } | undefined): void {
    if (sender) this.sender = sender
  }

  /**
   * 根据路径设置配置
   */
  @action.bound
  public setTargetConfig<T>(path: string, config: T): void {
    set(this.config, path, config)
  }

  /**
   * 根据模块数据
   */
  @action.bound
  public saveModulesConfig<T>(path: string, config: T): void {
    set(this.config, path, config)
  }

  /**
   * 根据设置眼睛显示
   */
  @action.bound
  public toggleEyeStatus(key: "text" | "si" | "pr" | "du"): void {
    const { status } = this.config.content[this.orderStatus]

    set(status, key, !status[key])
  }

  /**
   * 切换状态
   */
  @action.bound
  public setOrderStatus(status?: orderStatusType): void {
    if (status) {
      this.orderStatus = status
    }
  }

  /**
   * 获取Email Content
   */
  @action.bound
  getHTMLContent(): string {
    return this.config.content[this.orderStatus].content
  }

  /**
   * 获取Email 默认 subject
   */
  @action.bound
  getDefaultSubject(): string {
    return this.config.content_default[this.orderStatus].subject
  }

  /**
   * 获取Email subject
   */
  @action.bound
  getSubject(): string {
    return this.config.content[this.orderStatus].subject
  }

  /**
   * 设置Email Content
   */
  @action.bound
  setHTMLContent(html: string) {
    this.config.content[this.orderStatus].content = html
  }

  /**
   * 设置Content配置
   */
  @action.bound
  setContentConfig<T>(path: string, v: T) {
    set(this.config.content[this.orderStatus], path, v)
  }

  /**
   * 获取Content配置
   */
  @action.bound
  getContentConfig(): ContentType {
    return this.config.content[this.orderStatus]
  }

  /**
   * 切换配置/HTML编辑器
   */
  @action.bound
  public toggleHtmlEdit(isHtmlEdit?: boolean): void {
    if (isHtmlEdit === true || isHtmlEdit === false) {
      this.isHtmlEdit = isHtmlEdit
    } else {
      this.isHtmlEdit = !this.isHtmlEdit
    }
  }

  /**
   * 切换当前编辑区域
   */
  @action.bound
  public setActiveModule(type?: moduleType): void {
    if (type !== this.activeModule) {
      this.activeModule = type
    }
  }

  /**
   * 切换当前保存激活状态
   */
  @action.bound
  public setSaveBtnActive(type: boolean): void {
    this.saveBtnActive = type
  }

  /**
   * 切换当前Loading状态
   */
  @action.bound
  public setLoading(type: boolean): void {
    this.loading = type
  }

  /**
   * 切换保存Loading状态
   */
  @action.bound
  public setSaveLoading(type: boolean): void {
    this.saveLoading = type
  }

  /**
   * 设置collection选项
   */
  @action.bound
  public setSelectCollection(list: { label: string, value: string }[]): void {
    this.selectCollection = list
  }

  /**
   * 设置collection选项
   */
  @action.bound
  public setVariables(data: variablesType): void {
    this.variables = data
  }

  /**
   * 设置notification enabled配置
   */
  @action.bound
  public setNotificationEnabledData(data: Record<orderStatusType, boolean>): void {
    this.notification_enabled = data
  }

  /**
   * 设置notification enabled配置
   */
  @action.bound
  public setNotificationEnabled(enabled: boolean): void {
    this.notification_enabled[this.orderStatus] = enabled
  }

  @action.bound
  setDataLoadedTime(time: number) {
    this.dataLoadedTime = time
  }
  @action.bound
  toggleSubModalShow() {
    this.showSubModal = !this.showSubModal
  }
}

export default EmailEditStore
