export enum OrderStatus {
  All,
  Pending,
  InTransit,
  OutForDelivery,
  Delivered,
  Expired,
  FailedAttempt,
  Exception,
  InfoReceived,
}

export enum OrderSyncToPayPal {
  "SyncedSuccessfully" = 11,
  "SyncFailed" = 12,
  "Unsynced" = 10,
  "OtherPaymentMethods" = 0,
}
