import { AppPricingInterval } from "@/api/account/type.d"
import { SupportLanguages } from "@/i18n/SupportLanguages"
import { languageType } from "@/pages/Settings/EmailEdit/type"

export enum SpecialOfferType {
  ShowAll = 0,
  ShowButton = 2,
  Hidden = 4,
}

export type Txt = {
  upgrade_now?: string;
};

export type GetQaaList = {
  title: string;
  url: string;
};

export type PaidServiceFuncConfig = {
  show_branding?: boolean;
  auto_translate?: boolean;
  export?: boolean
};

export type LocalVar = {
  showQuotaRemain: boolean;
  showFreeAdd: boolean
}

export type ITrackingPage = {
  status_config: ITrackingPageStatusConfig
}

export type ITrackingPageStatusConfig = Record<keyof ITrackingPageStatus, {
  en: string;
  cn: string;
  status: number;
  img: string;
}>

export interface ITrackingPageStatus {
  blank: string;
  pending: string;
  transit: string;
  pickup: string;
  delivered: string;
  expired: string;
  undelivered: string;
  exception: string;
  info_received: string;
}

export enum BetaFeature {
  // 快捷键
  SHORTCUTS = "shortcuts",

  // 进入新的 shipment info 页面
  NEW_SHIPMENT_INFO_PAGE = "new_shipment_info_page",

  // 修改运输商
  UPDATE_SHIPMENT_COURIER = "update_shipment_courier",

  // 忽略权限校验
  IGNORE_PERMISSION_CHECK = "ignore_permission_check",

  // 自定义导出
  CUSTOM_EXPORT_SHIPMENTS = "custom_export_shipments",

  // 选择所有日期
  SELECT_ALL_DATE = "select_all_date",

  // Orders 自定义每页展示多少订单
  ORDERS_CUSTOM_PER_PAGE = "orders_custom_per_page",
}

export type BetaFeatures = {
  report_url: string
  features: BetaFeature[]
}


export enum BannerEnum {
  MARKING_PROTECTION_BANNER = 28694,
  ORDERS_EXCEPTION_BANNER = 28696,
  NOTIFICATION_EXCEPTION_BANNER = 28698,
  NOTIFICATION_EXCEPTION_TO_SELF_BANNER = 28700,
  SEO = 28702,
  TT = 28704,
  DSM = 28706,
  PAGEFLY = 28708,
  CrossSale = 29002
}

export type Data = {
  // 实验性功能
  beta_features: BetaFeatures
  // 自定义 JS 注入
  inject_js?: string

  plan_interval: AppPricingInterval
  ShopName: string;
  store_name: string;
  store_real_name: string;
  currency: string;
  notice_one?: number;
  notice_two?: number;
  notice_thr?: number;
  notice_fou?: number;
  notice_fiv?: number;
  notice_twe?: number;
  notice_the?: number;
  notice_partner_f?: number;
  notice_partner_d?: number;
  notice_sharing?: number;
  is_show_partner_friendly_banner?: number;
  notice_free_order_add: number;
  show_double_free_quota_tip: boolean;
  notice_firsync?: number;
  buttonSwitchAll?: any;
  first_finish_sync_time?: number;
  reg_gt_1day?: boolean;
  noticeShow?: number;
  syncDay?: number;
  plan: number;
  plan_name: string;
  plan_member: number;
  plan_price: number;
  total_order_count: number;
  consume_order_count?: number;
  user_id?: string;
  user_email: string;
  user_name: string;
  shop_name: string;
  is_frew?: number;
  is_pay?: number;
  last_track_url?: string;
  is_trial?: any;
  trial_days?: number;
  txt: Txt;
  primary_locale?: languageType
  getQaaList?: GetQaaList[];
  paid_service_func_config?: PaidServiceFuncConfig;
  show_review_guide?: boolean;
  rating?: number;
  review_name?: any;
  shop_plan_display_name: string;
  protection: string;
  special_offer: SpecialOfferType;
  last_30d_count: number;
  showNewUserGuide: boolean;
  remaining_order_count: number
  quotaReminder: string

  // cross-sell 相关
  cross_sell_flag?: string;

  show_advertising_popup?: boolean
  is_in_cn?: boolean // 是否是中国区域的用户
  show_account_tip?: boolean // 套餐异常提示
  cross_sell_eligible?: boolean // 用户是否符合 Cross-sell 条件
  cross_sell_customer_language?: "Chinese"|"Other" // 枚举 Chinese Other
  show_account_tip_a?: boolean // account 页面异常提示
  selected_lang: SupportLanguages// 用户选择的语言
  tracking_page?: ITrackingPage

  discount: any;
  discount_start: boolean;
  pre_edd_app_block_active_state?: boolean;
  money_with_currency_format: string
  money_in_emails_format: string
  is_paypal_connected: boolean
  is_legacy_klaviyo_connected?: true // 临时增加：klaviyo 集成迁移提醒
  timezone: string,
  subscription_payment_method?: number, // 如果为1时 1. 禁用套餐订阅按钮 2. 禁用 Usage charge 相关配置（参考免费订阅用户）
  // 2024-11-15 新增
  shop_locales: languageType[]
  billing: {
    quota_reset_date: string,
  },

  // 套餐的版本
  current_subscription: {
    version: number,
  },
  // insurance 是否符合条件
  is_pi_eligible: boolean
  // insurance 是否活跃
  is_pi_active: boolean
  // is_pi_auth（是否授权）
  is_pi_auth: boolean
  // is_pi_payment_method 绑卡（是否设置支付方式）
  is_pi_payment_method: boolean
  // 是由有过exception的订单
  has_exception_shipment: boolean

  banner_data: { id: number, display_on_page: string, display: boolean, image_url: string }[]

  "banner_discount_v2024_04": {
    "dashboard": false,
    "billing": false,

    "dashboard_banner_url": string,
    "billing_banner_url": string,

    "main_banner_url": string,
    "second_banner_url": string,

    // 调试字段，没有用途
    "from": 1
  }

  current_discount: {
    code: string,
    interval: AppPricingInterval,
  },
  can_remove_pp_branding: boolean
  show_pp_branding: boolean
};

export type IUserInfo = {
  code: number;
  msg: string;
  data?: Data;
};

export type IUserInfoStoreData = {
  data: Data
  local: Record<keyof LocalVar, any>

  /**
   * 获取当前套餐等级
   */
  get currentPlanLevel(): number

  /**
   * 获取剩余额度百分比  0-100 区间
   */
  get consumeOrderCountPercentage(): number

  /**
   * 是否显示额度不足提醒
   */
  get showQuotaNotice(): boolean

  /**
   * 是否显示评论引导 banner
   */
  get showReviewGuide(): boolean

  /**
   * 是否显示首次同步提示
   */
  get showFirstSync(): boolean

  /**
   * 是否显示移除 Branding banner
   */
  get showRemoveBranding(): boolean

  /**
   * 是否显示免费用户添加额度
   */
  get showFreeOrderAdd(): boolean

  /**
   * 是否显示新手引导板块
   */
  get showBeginnersGuide(): boolean

  /**
   * 是否为免费用户<老用户不算免费用户>
   */
  get isFreeUser(): boolean


  /**
   * 判断是否为老用户
   */
  get isOldUser(): boolean

  /**
   * 是否为付费的老用户
   */
  get oldPayingUsers(): boolean

  /**
   * 设置数据
   * @param data
   */
  setData(data?: Data): void

  /**
   * 修改 Data 相关 key 的 value
   * @param key
   * @param val
   */
  setDataSingle<K extends keyof Data>(key: K, val: Data[K]): void

  setDiscountSingle<K extends keyof Data["discount"]>(key: K, val: Data["discount"][K]): void;

  setButtonSwitchAll<K extends keyof Data["buttonSwitchAll"]>(key: K, val: Data["buttonSwitchAll"][K]): void;

  /**
   * 设置临时局部变量
   * @param key
   * @param val
   */
  setLocalVar(key: keyof LocalVar, val: any): void

  /**
   * 设置数据是否加载完成
   * @param status
   */
  setIsLoadData(status: boolean): void

  /**
   * 是否显示 "移除 Branding"的 banner
   * @return {boolean}
   */
  get showRemoveBrandingBanner(): boolean;
}
