import "@/i18n/config"
import "@shopify/polaris/build/esm/styles.css"
import "./assets/css/app.scss"

import { AppProvider, Frame, Toast } from "@shopify/polaris"
import { ClickToComponent } from "click-to-react-component"
import { Observer, Provider } from "mobx-react"
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { IntercomProvider } from "react-use-intercom"

import { AppActionName, ReceivedData } from "@/admin-bridge/admin-bridge"
import App from "@/App"
import stores from "@/stores"
import AppBridge, { isShopifyEmbedded } from "@/utils/appBridge"
import { isFromAdmin } from "@/utils/is-from-admin"
import { LanguageUtils } from "@/utils/languages"

// 路径选择
const basename: string | undefined = "/"

window.ParcelPanel = {
  isFromAdmin: false,
  hiddenIntercom: false,
}

const searchParams = new URLSearchParams(window.location.search)
const authToken = String(searchParams.get("authToken"))

if (searchParams.has("authToken") && isFromAdmin(authToken)) {
  window.isFromAdmin = true
  stores.commonStore.setAuthToken(authToken)

  window.ParcelPanel.isFromAdmin = true
  window.ParcelPanel.hiddenIntercom = searchParams.has("hidden_intercom") && searchParams.get("hidden_intercom") === "1"

  // 监听父页面发送过来的消息
  window.addEventListener("message", function (event: WindowEventMap["message"]) {
    // 判断消息来源是否是父页面
    if (event.source !== window.parent) {
      return
    }

    const receivedData: ReceivedData<any> = event.data

    if (receivedData.action === AppActionName.SessionToken_Respond) {
      // 如果是发送过来的 token
      stores.commonStore.setAuthToken(receivedData.data.token)
    } else if (receivedData.action === AppActionName.SelectLanguage_Respond) {
      // 如果是发送过来的语言
      LanguageUtils.setLocale(receivedData.data.language, undefined, true)
    }
  })
}

/** 非生产环境下可自定义用户 ID */
if (import.meta.env.MODE !== "production") {
  const queryUid = searchParams.get("uid") || ""
  const lsUid = localStorage.getItem("pps_uid") || ""
  let uid = ""

  if (queryUid.length > 0) {
    localStorage.setItem("pps_uid", queryUid)
    uid = queryUid
  } else if (lsUid.length > 0) {
    uid = lsUid
  }
  stores.commonStore.setAuthToken(uid)
}

if (
  searchParams.has("host")
  && !window.isFromAdmin // 不是超管后台
  && import.meta.env.MODE !== "development" // 不是开发环境
  && isShopifyEmbedded()
  && searchParams.has("embedded")
  && searchParams.get("embedded") === "1"
) {
  try {
    AppBridge.set(searchParams.get("host") || "")
  } catch (e) {
    // @ts-ignore
    AppBridge.app = undefined
    console.log("init app bridge", e)
  }
}


// 创建根组件
const root = createRoot(document.getElementById("root")!)

root.render(
  <React.StrictMode>
    <ClickToComponent />
    <Observer>{() =>
      <AppProvider i18n={LanguageUtils.getAppProviderI18n()}>
        <IntercomProvider
          appId="t6tndyrj"
          initializeDelay={3_000}
        >
          <Provider {...stores}>
            <Frame>
              <BrowserRouter basename={basename}>
                <App />
                <>
                  {
                    Object.entries(stores.commonStore.toastItems)
                      .map(([id, v]) => <Toast key={id} {...v} />)
                  }
                </>
              </BrowserRouter>
            </Frame>
          </Provider>
        </IntercomProvider>
      </AppProvider>
    }</Observer>
  </React.StrictMode>,
)
